import React, { useEffect, useState } from "react";
import { Card, Button, Select, DatePicker, Spin, Modal, Row, Col } from "antd";
import dayjs from "dayjs";
import useFetch from "../../../hooks/useFetch";
import "./QuestionHistory.css";
import { gradeList } from "../../../services/data";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";

const { Option } = Select;
const { RangePicker } = DatePicker;

const QuestionHistory = () => {
  const [filteredExams, setFilteredExams] = useState([]);
  const [filters, setFilters] = useState({
    grade: "Grade 1",
    subject: "English",
    dateRange: [dayjs("2020-01-01"), dayjs("2028-12-31")],
  });
  const [dataRes, setDataRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [subjLoading, setSubjLoading] = useState(false);
  const [subjFetchError, setSubjFetchError] = useState(null);
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const [subjectList, setSubjectList] = useState([]);

  const { get: fetchSubj } = useFetch("/api/subject", {
    setLoading: setSubjLoading,
    setError: setSubjFetchError,
    setData: setSubjects,
  });
  const { get: fetchExams } = useFetch("/api/questions/adminHistory", {
    setLoading,
    setError: setFetchError,
    setData: setDataRes,
  });
  useEffect(() => {
    if (Array.isArray(dataRes.exams)) {
      setFilteredExams(dataRes.exams); // Set the filtered exams to be displayed
    }
  }, [dataRes]);
  useEffect(() => {
    fetchSubj();
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (gradeDivisions?.length) {
      setFilters((prev) => ({
        ...prev,
        grade: gradeDivisions[0].name, // Set initial grade
      }));
    }
  }, [gradeDivisions]);

  useEffect(() => {
    if (subjects?.length) {
      setSubjectList(subjects);
      setFilters((prev) => ({
        ...prev,
        subject: subjects[0].name, // Set initial subject
      }));
    }
  }, [subjects]);

  useEffect(() => {
    const { grade, subject, dateRange } = filters;
    const startDate = dateRange[0]?.format("YYYY-MM-DD") || "";
    const endDate = dateRange[1]?.format("YYYY-MM-DD") || "";
    fetchExams({ grade, subject, startDate, endDate });
  }, [filters]);

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleDateChange = (dates) => {
    setFilters((prev) => ({ ...prev, dateRange: dates || [] }));
  };

  const showModal = (exam) => {
    setSelectedExam(exam);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedExam(null);
  };

  return (
    <div className="admin-dashboard-container">
      <h2>Admin Dashboard</h2>

      <div className="filter-section">
        <Select
          value={filters.grade}
          onChange={(value) => handleFilterChange("grade", value)}
          label="Grade"
          name="grade"
        >
          {gradeDivisions?.map((gra) => (
            <Option key={gra.name} value={gra.name}>
              {gra.name}
            </Option>
          ))}
        </Select>

        <Select
          value={filters.subject}
          onChange={(value) => handleFilterChange("subject", value)}
          label="Subject"
          name="subject"
        >
          {subjectList?.map((sub) => (
            <Option key={sub.name} value={sub.name}>
              {sub.name}
            </Option>
          ))}
        </Select>

        <RangePicker
          style={{ marginRight: 10 }}
          value={filters.dateRange}
          onChange={handleDateChange}
        />
      </div>

      {loading ? (
        <Spin size="large" />
      ) : filteredExams.length === 0 ? (
        <p>No exams found with the selected filters.</p>
      ) : (
        <div className="exam-list">
          {filteredExams.map((exam, index) => (
            <Card
              key={exam.id}
              className="exam-card mini-list-card"
              style={{
                display: "inline-block",
                width: "calc(50% - 10px)",
                marginBottom: 10,
                marginRight: index % 2 === 0 ? 10 : 0,
              }}
            >
              <div className="exam-mini-info">
                <p>
                  <strong>Grade:</strong> {exam.grade}
                </p>
                <p>
                  <strong>Subject:</strong> {exam.subject}
                </p>
                <p>
                  <strong>Date:</strong> {exam.updatedAt.slice(0, 10)}
                </p>
              </div>
              <Button type="primary" onClick={() => showModal(exam)}>
                View Details
              </Button>
            </Card>
          ))}
        </div>
      )}

      <Modal
        title="Exam Details"
        visible={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
        width={800}
      >
        {selectedExam && (
          <div>
            <div
              className="modal-header"
              style={{
                marginBottom: 20,
                borderBottom: "1px solid #ddd",
                paddingBottom: 10,
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <p>
                    <strong>Grade:</strong> {selectedExam.grade}
                  </p>
                  <p>
                    <strong>Subject:</strong> {selectedExam.subject}
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <strong>Semester:</strong> {selectedExam.semester}
                  </p>
                  <p>
                    <strong>Assessment Type:</strong>{" "}
                    {selectedExam.assessmentType}
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <strong>Time Limit:</strong> {selectedExam.timeLimit}
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <strong>Date:</strong> {selectedExam.updatedAt.slice(0, 10)}
                  </p>
                </Col>
              </Row>
            </div>

            <div className="questions-container">
              <h4>Questions and Answers:</h4>
              {selectedExam.questions.map((qa, idx) => (
                <div
                  key={idx}
                  className="question-answer"
                  style={{ marginBottom: 10 }}
                >
                  <p>
                    <strong>Q{idx + 1}:</strong> {qa.question}
                  </p>
                  <p>
                    <strong>A{idx + 1}:</strong> {qa.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuestionHistory;
