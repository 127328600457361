import { jsPDF } from "jspdf";
import { message } from "antd";

export const printTimeTable = (timeTable, grade, section) => {
  const doc = new jsPDF("p", "pt", "a4");

  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  const margin = 15;
  const headerFontSize = 16;
  const tableFontSize = 12;
  const rowHeight = 30;
  const tableStartX = margin;
  const tableStartY = 100;

  const columnWidths = [80, 80, 80, 80, 80, 80, 80];

  const columnHeaders = [
    "Days",
    "Period 1",
    "Period 2",
    "Period 3",
    "Period 4",
    "Period 5",
    "Period 6",
  ];

  // Header
  doc.setFontSize(headerFontSize);
  doc.text(
    `Timetable for Grade: ${grade} Section: ${section}`,
    pageWidth / 2,
    50,
    {
      align: "center",
    }
  );

  // Draw column headers
  doc.setFontSize(tableFontSize);
  doc.setFont("helvetica", "bold");

  let currentY = tableStartY;

  columnHeaders.forEach((header, index) => {
    const xPosition =
      tableStartX + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
    doc.text(header, xPosition + columnWidths[index] / 2, currentY, {
      align: "center",
    });
  });

  // Draw timetable rows
  doc.setFont("helvetica", "normal");
  currentY += rowHeight;

  timeTable.forEach((entry) => {
    let currentX = tableStartX;

    // Day column
    doc.text(entry.day || "-", currentX + columnWidths[0] / 2, currentY, {
      align: "center",
    });
    currentX += columnWidths[0];

    // Period columns
    for (let i = 1; i <= 6; i++) {
      const periodKey = `period${i}`;
      doc.text(
        entry[periodKey] || "-",
        currentX + columnWidths[i] / 2,
        currentY,
        { align: "center" }
      );
      currentX += columnWidths[i];
    }

    currentY += rowHeight;

    // Add a new page if the content exceeds the page height
    if (currentY + rowHeight > pageHeight - margin) {
      doc.addPage();
      currentY = tableStartY;
    }
  });

  // Save the PDF
  doc.save(`Timetable_Grade_${grade}_Section_${section}.pdf`);
};
