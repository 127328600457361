import React from "react";
import { Modal, Button } from "antd";
import "./Receipt.css"; // Create a separate CSS file for styling

const ReceiptModal = ({ visible, onOk, onCancel, receiptData }) => {
  if (!receiptData) return null; // Handle case when no payment is selected
  const columns = [
    {
      title: "Payment ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Passenger",
      dataIndex: "passengerSearch",
      key: "passengerSearch",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${amount.toFixed(2)}`, // Making sure the cash looks good!
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Payment Reason",
      dataIndex: "paymentReason",
      key: "paymentReason",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Month/Year",
      dataIndex: "monthYear",
      key: "monthYear",
      render: (date) => `${date.month}/${date.year}`, // Format: MM/YYYY
    },
    {
      title: "Payer Name",
      dataIndex: "payerName",
      key: "payerName",
    },
    {
      title: "TIN Number",
      dataIndex: "tinNumber",
      key: "tinNumber",
    },
    {
      title: "Description",
      dataIndex: "paymentDescription",
      key: "paymentDescription",
    },
  ];
  return (
    <Modal
      title="Receipt"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="print" type="primary" onClick={onOk}>
          Print
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <div className="receipt-container">
        <div className="store-info">
          <h2> Hamza school</h2>
          <p>TIN: 0000083152 VAT:</p>
        </div>

        <div className="receipt-header">
          <h3>Cash Sales Voucher</h3>
          <p>Voucher No: {receiptData.id}</p>
          <p>Date: {new Date().toLocaleDateString()}</p>
          <p>
            <strong>Payment ID:</strong> {receiptData.id}
          </p>

          <p>
            <strong>Status:</strong> {receiptData.status}
          </p>
        </div>

        <div className="customer-info">
          <p>
            <strong>Passenger: {receiptData.passengerSearch}</strong>
          </p>
          <p>Amount: ${receiptData.amount}</p>
        </div>

        {/* Receipt Items Table */}
        <table className="receipt-table">
          <thead>
            <tr>
              <th>SN</th>
              <th>Description</th>
              <th>Qty</th>
              <th>U.Amount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {/* Assuming receiptData contains an items array */}
            {receiptData &&
            receiptData.items &&
            receiptData.items.length > 0 ? (
              receiptData.items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.qty}</td>
                  <td>${item.unitAmount.toFixed(2)}</td>
                  <td>${item.total.toFixed(2)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No items available</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="summary">
          <p>
            <strong>Grand Total: ${receiptData.amount}</strong>
          </p>
        </div>

        <div className="payment-info">
          <p>Payment Method: Cash</p>
        </div>

        <div className="voucher-info">
          <p>Prepared by: YourCompanyName</p>
        </div>
      </div>
    </Modal>
  );
};

export default ReceiptModal;
