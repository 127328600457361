import React from "react";
import { Button } from "antd";
import DynamicTable from "../DynamicTable";

const OrganizeStudentTable = ({
  studentsData,
  fetchLoading,
  handleArrange,
}) => {
  const columns = [
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "No. of Classrooms",
      dataIndex: "classrooms",
      key: "classrooms",
      render: (_, record) => record.classrooms || 0,
    },
    {
      title: "No. of Students",
      dataIndex: "studentCount",
      key: "studentCount",
      render: (_, record) => record.studentCount || 0,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleArrange(record)}>
          Arrange
        </Button>
      ),
    },
  ];

  return (
    <DynamicTable
      data={studentsData}
      loading={fetchLoading}
      customColumns={columns}
      rowKey="grade"
      scroll={{ x: 700 }}
    />
  );
};

export default OrganizeStudentTable;
