import React, { useState, useMemo } from "react";

import DynamicTable from "../DynamicTable";

const AttendanceTable = ({ attendanceData }) => {
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const dynamicColumns = [
      {
        title: "Student Info",
        key: "studentInfo",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record.name.toUpperCase()}
            </div>
            <div className="tableSubCols details">ID: {record.id}</div>
            <div className="tableSubCols details">Sex: {record.sex}</div>
          </>
        ),
      },
    ];

    const attendanceColumns = Array.from({ length: 30 }, (_, index) => ({
      title: (index + 1).toString(),
      dataIndex: ["attendance", index],
      key: `attendance-${index + 1}`,
    }));

    return [...dynamicColumns, ...attendanceColumns];
  };

  const columns = useMemo(() => generateColumns(attendanceData), []);

  return (
    <DynamicTable
      data={attendanceData}
      customColumns={columns}
      scroll={{ x: 3800 }}
    />
  );
};

export default AttendanceTable;
