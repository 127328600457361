import React, { useState, useEffect } from "react";
import { Button, Select, Modal, InputNumber, message } from "antd";
import OrganizeStudentTable from "../../tables/oraganize-student-table/OrganizeStudentTable";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";

const { Option } = Select;

const OrganizingStudents = () => {
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classrooms, setClassrooms] = useState(0);
  const [sectionNaming, setSectionNaming] = useState("");

  // State for fetch data
  const [studentsData, setStudentsData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  // State for post data
  const [postRes, setPostRes] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);

  const { post: postStudents, reset: resetArrange } = usePost(
    "/api/arrangeStudents",
    {
      setLoading: setPostLoading,
      setError: setPostError,
      setData: setPostRes,
    }
  );

  const { get: fetchStudents } = useFetch("/api/arrangeStudents", {
    setLoading: setFetchLoading,
    setError: setFetchError,
    setData: setStudentsData,
  });

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    if (postRes?.message) {
      message[postRes?.status || "success"](postRes?.message);

      if (postRes?.status === "success") {
        setStudentsData((prevData) =>
          prevData.map((item) =>
            item.grade === selectedGrade.grade ? { ...item, classrooms } : item
          )
        );
        setIsModalVisible(false);
        resetArrange();
      }
    }
  }, [postRes]);

  const handleOk = () => {
    const studentsToArrange = studentsData.filter(
      (student) => student.grade === selectedGrade.grade
    );

    const students = studentsToArrange.map((student) => ({
      st_unique_id: student?.st_unique_id || "unknown",
      gender: student?.gender || "unknown",
      prev_average: student?.prev_average || 0,
    }));

    postStudents({
      id: selectedGrade.id,
      classrooms,
      sectionNaming,
      grade: selectedGrade.grade,
      students,
    });
  };

  const handleArrange = (record) => {
    setSelectedGrade(record);
    setClassrooms(record.classrooms);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h1>Arrange Students</h1>
      <OrganizeStudentTable
        studentsData={studentsData}
        fetchLoading={fetchLoading}
        handleArrange={handleArrange}
      />
      <Modal
        title={`Arrange ${selectedGrade ? selectedGrade.grade : ""}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={postLoading}
      >
        <div>
          <p>Number of Classrooms:</p>
          <InputNumber
            min={1}
            value={classrooms}
            onChange={(value) => setClassrooms(value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <p>Section Naming:</p>
          <Select
            placeholder="Select a Section Name"
            onChange={(value) => setSectionNaming(value)}
          >
            <Option value="Alphabet">Alphabet</Option>
            <Option value="Number">Number</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default OrganizingStudents;
