import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { DashboardOutlined } from "@ant-design/icons";
import { FaUsers } from "react-icons/fa";
import { useCollapsed } from "../../context/CollapsedContext";

const { Sider } = Layout;

const RegistrationMenuItems = [
  {
    id: "dashboard",
    name: "Dashboard",
    icon: <DashboardOutlined />,
    link: "dashboard",
    submenu: [{ name: "Admin", href: "/app/registrar/admin-dashboard" }],
  },
  {
    id: "registration",
    name: "Registration",
    icon: <FaUsers />,
    link: "student",
    submenu: [
      { name: "Student", href: "/app/registrar/students" },
      { name: "Staff", href: "/app/registrar/staff" },
    ],
  },
  {
    id: "payment",
    name: "Payment",
    icon: <FaUsers />,
    link: "payment",
    submenu: [{ name: "School Fee", href: "/app/registrar/payment" }],
  },
];

export default function RegistrarSiderComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, setCollapsed } = useCollapsed();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1000;
      setIsSmallScreen(smallScreen);
      setCollapsed(smallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  const constructMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.submenu) {
        return {
          key: item.link,
          icon: item.icon,
          label: item.name,
          children: item.submenu.map((sub) => ({
            key: sub.href,
            label: sub.name,
          })),
        };
      }
      return {
        key: item.link,
        icon: item.icon,
        label: item.name,
      };
    });
  };

  const menuItems = constructMenuItems(RegistrationMenuItems);

  return (
    <Layout className="custom-sider">
      <Sider
        style={{ backgroundColor: "white" }}
        collapsible={isSmallScreen}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        collapsedWidth={0}
        breakpoint="md"
        trigger={null}
      >
        <Menu
          style={{ backgroundColor: "white", marginTop: 30 }}
          mode="inline"
          selectedKeys={[location.pathname]} // Highlight current path
          onClick={handleMenuClick}
          items={menuItems}
        />
      </Sider>
    </Layout>
  );
}
