import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Select, Input, Row, Col, message, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";
import DynamicTable from "../../tables/DynamicTable";
import useFetch from "../../../hooks/useFetch";
import { convertToDisplayDate } from "../../../utils/helper";
import StaffIdTable from "../../tables/staff-Id-table/StaffIdTable.jsx";
import { printStudId } from "../../../utils/printId.js";
import { printStaffId } from "../../../utils/printStaffId.js";
const StaffId = () => {
  const [showPrintId, setShowPrintId] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [staffToGenerateID, setStaffToGenerateID] = useState([]);
  const [fetchIdLoading, setFetchIdLoading] = useState(false);
  const [fetchIdError, setFetchIdError] = useState(null);
  const [idData, setIdData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);

  // Fetch data hooks
  const { get: fetchStaffs } = useFetch("/api/user", {
    setLoading,
    setData: setStaffData,
  });
  const { get: fetchId } = useFetch("/api/staffId", {
    setLoading: setFetchIdLoading,
    setError: setFetchIdError,
    setData: setIdData,
  });

  useEffect(() => {
    fetchStaffs();
    fetchId();
  }, []);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const addStaffToGenerateID = (staff) => {
    if (staffToGenerateID.some((s) => s["ID"] === staff["ID"])) {
      message.info("Staff is already in the list for ID generation.");
    } else {
      setStaffToGenerateID((prev) => [...prev, staff]);
    }
  };

  const removeStaffFromGenerateID = (staffID) => {
    setStaffToGenerateID((prev) => prev.filter((s) => s["ID"] !== staffID));
  };

  const handlePrintId = () => {
    if (staffToGenerateID.length > 0) {
      printStaffId(staffToGenerateID, idData.imgUri);
      message.success("ID(s) Printed!");
    } else {
      message.warning("No staff selected to print IDs.");
    }
  };

  return (
    <div id="StaffId">
      <div className="header">
        <h2>Staff ID Management</h2>
        <Breadcrumb />
      </div>
      <Card
        title="Manage Staff IDs"
        bordered={false}
        style={{ margin: "20px" }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={(value) => setSelectedColumn(value)}
              style={{ width: 200 }}
              allowClear
            >
              {[
                "FULL NAME",
                "EMAIL",
                "EDUC LEVEL",
                "ROLE",
                "SEX",
                "REGION",
                "SUB CITY",
                "PHONE",
                "CREATED",
                "UPDATED",
                "CREATED BY",
              ].map((col) => (
                <Select.Option key={col} value={col}>
                  {col}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <StaffIdTable
          title="All Staff"
          data={staffData}
          loading={loading}
          searchText={searchText}
          selectedColumn={selectedColumn}
          handleAdd={addStaffToGenerateID}
          actionType="add"
        />
        <h2 style={{ margin: 20 }}>Staff's Who needs Id</h2>
        <StaffIdTable
          title="Staff Who Need ID"
          data={staffToGenerateID}
          loading={fetchIdLoading}
          handleRemove={removeStaffFromGenerateID}
          actionType="remove"
        />
        <Button
          type="primary"
          disabled={staffToGenerateID.length === 0}
          style={{ marginTop: 16 }}
          onClick={handlePrintId}
        >
          Print ID
        </Button>
      </Card>
    </div>
  );
};

export default StaffId;
