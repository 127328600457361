import { useRoutes } from "react-router-dom";
import StudentRegistration from "../../components/registration-form-container/student-registration/StudentRegistration";
import StaffRegistration from "../../components/registration-form-container/staff-registration/StaffRegistration";
import Payment from "../../components/payment/Payment";

const RegistrarRoutes = () => {
  const routes = useRoutes([
    { path: "/students", element: <StudentRegistration /> },
    {
      path: "/staff",
      element: <StaffRegistration />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/*",
      element: <StudentRegistration />,
    },
  ]);

  return routes;
};

export default RegistrarRoutes;
