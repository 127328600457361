import React, { useState, useMemo } from "react";
import { Button, Input, Row, Col, Select } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";

const AssessmentListTable = ({ assData = [], assLoading, setModalClosed }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");

  const filteredData = useMemo(() => {
    if (!assData) return [];

    return assData.filter((item) => {
      if (!selectedColumn || !searchText) return true;

      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [assData, searchText, selectedColumn]);
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  return (
    <div id="AssessmentListTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}></Row>
      <DynamicTable
        data={filteredData}
        loading={assLoading}
        scroll={{ x: 1800 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default AssessmentListTable;
