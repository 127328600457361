import React, { useEffect, useState } from "react";
import { Form, Input, Select, Tag } from "antd";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
const { Option } = Select;

function SubjectForm({ editingSubject, form }) {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [calcMin, setCalcMin] = useState(0);

  useEffect(() => {
    // Dynamically set form values
    editingSubject && form.resetFields();
    form.setFieldsValue(editingSubject);
  }, [form]);

  const handleFormChange = async (e) => {
    const maxVal = form.getFieldValue("max");

    if (form.getFieldValue("max") && form.getFieldValue("min")) {
      setCalcMin(Math.round(maxVal * (form.getFieldValue("min") / 100)));
      form.setFieldValue("minPercentage", calcMin);
    }
  };

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  return (
    <Form
      onChange={handleFormChange}
      form={form}
      layout="vertical"
      initialValues={{ type: "academic" }}
    >
      <Form.Item
        label="Subject Name"
        name="name"
        rules={[{ required: true, message: "Please input the subject name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Subject code"
        name="code"
        rules={[{ required: true, message: "Please input the code!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Grades"
        name="grades"
        rules={[{ required: true, message: "Please select grades" }]}
      >
        <Select mode="multiple">
          {gradeDivisions.map((gra) => (
            <Option key={gra.name} value={gra.name}>
              {gra.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please select the subject type!" }]}
      >
        <Select>
          <option value="academic">Academic</option>
          <option value="non_academic">Non Academic</option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Order"
        name="order"
        rules={[{ required: true, message: "Please input the term order!" }]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  );
}

export default SubjectForm;
