import { useRoutes } from "react-router-dom";
import TeacherDashboard from "../../components/teacher-components/teacher-dashboard/TeacherDashboard";
import Assessments from "../../components/teacher-components/assessments/Assessments";
import Attendance from "../../components/teacher-components/attendance/Attendance";
import Announcement from "../../components/teacher-components/announcement/Announcement";
import Message from "../../components/teacher-components/message/Message";
import TeacherQuestionGenerator from "../../components/teacher-components/Teacher-question-generator/TeacherQuestionGenerator";
import GeneratedQuestionHistory from "../../components/teacher-components/generated-question-history/GeneratedQuestionHistory";

const TeacherRoutes = () => {
  const routes = useRoutes([
    { path: "/dashboard", element: <TeacherDashboard /> },

    {
      path: "/assessments",
      element: <Assessments />,
    },
    {
      path: "/attendance",
      element: <Attendance />,
    },
    {
      path: "/announcement",
      element: <Announcement />,
    },
    {
      path: "/message",
      element: <Message />,
    },
    {
      path: "/question",
      element: <TeacherQuestionGenerator />,
    },

    {
      path: "/history",
      element: <GeneratedQuestionHistory />,
    },
    {
      path: "/*",
      element: <TeacherDashboard />,
    },
  ]);

  return routes;
};

export default TeacherRoutes;
