import { Button, Card, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph } = Typography;

const ConfigurationPendingComp = () => {
  const navigate = useNavigate();

  return (
    <Card bordered={true} style={styles.card}>
      <Title level={3} style={styles.title}>
        🎉 Profile Created Successfully! 🎉
      </Title>
      <Paragraph style={styles.paragraph}>
        ✅ You have successfully created your school profile. <br />
        ⏳ Your system will be live within 24 hours. <br />
        🙏 Thank you for choosing our service!
      </Paragraph>
      <Button type="primary" onClick={() => navigate("/")}>
        Back To Home
      </Button>
    </Card>
  );
};

const styles = {
  card: {
    maxWidth: "400px",
    margin: "20px auto",
    textAlign: "center",
  },
  title: {
    color: "#52c41a",
  },
  paragraph: {
    fontSize: "16px",
    color: "#333",
  },
};

export default ConfigurationPendingComp;
