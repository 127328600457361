import React, { createContext, useContext, useState } from "react";

// Create context
const CollapsedContext = createContext();

// Custom hook to use the context
export const useCollapsed = () => {
  return useContext(CollapsedContext);
};

// Provider component
export const CollapsedProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1000);

  return (
    <CollapsedContext.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </CollapsedContext.Provider>
  );
};
