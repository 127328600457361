import React, { useState, useMemo } from "react";
import { Card, Row, Col, Select, Button, Modal, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AnnouncementForm from "../../forms/announcement-form/AnnouncementForm";
import { grade, section } from "../../../services/data";

const { Option } = Select;

const sampleAnnouncements = [
  {
    id: 1,
    message: "Parent-Teacher meeting on Friday.",
    grade: "1",
    section: "A",
  },
  { id: 2, message: "Sports Day next Wednesday.", grade: "2", section: "B" },
];

const Announcement = () => {
  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("A");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [announcements, setAnnouncements] = useState(sampleAnnouncements);

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);

  const handleSendAnnouncement = (announcementMessage) => {
    const newAnnouncement = {
      id: announcements.length + 1,
      message: announcementMessage,
      grade: selectedGrade,
      section: selectedSection,
    };
    setAnnouncements([...announcements, newAnnouncement]);
  };

  const filteredAnnouncements = useMemo(() => {
    return announcements.filter(
      (announcement) =>
        announcement.grade === selectedGrade &&
        announcement.section === selectedSection
    );
  }, [announcements, selectedGrade, selectedSection]);

  return (
    <div id="Announcement">
      <h2>Announcement Page</h2>
      <Card title="Announcements" bordered={false} style={{ margin: "20px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              defaultValue={selectedGrade}
              onChange={handleGradeChange}
              style={{ width: 200 }}
            >
              {grade.map((gra, index) => (
                <Option key={index} value={gra.val}>
                  {gra.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="Select Section"
              defaultValue={selectedSection}
              onChange={handleSectionChange}
              style={{ width: 200 }}
            >
              {section.map((sec, index) => (
                <Option key={index} value={sec}>
                  {sec}
                </Option>
              ))}
            </Select>
          </Col>
          {/* <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add Announcement
              </Button>
            </div>
          </Col> */}
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={filteredAnnouncements}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={"Announcement"}
                // title={`Announcement for Grade ${item.grade} Section ${item.section}`}
                description={item.message}
              />
            </List.Item>
          )}
        />
        {/* <Modal
          title="Send Announcement"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <AnnouncementForm
            selectedGrade={selectedGrade}
            selectedSection={selectedSection}
            onSendAnnouncement={(announcementMessage) => {
              handleSendAnnouncement(announcementMessage);
              setIsModalVisible(false);
            }}
          />
        </Modal> */}
      </Card>
    </div>
  );
};

export default Announcement;
