import React, { useState, useMemo } from "react";
import { Card, Row, Col, Select, Button, Modal, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import MessageForm from "../../forms/message-form/MessageForm";
import { grade, section } from "../../../services/data";
import usePost from "../../../hooks/usePost";
import "./Message.css";

const { Option } = Select;

const sampleMessages = [
  {
    id: 1,
    studentName: "Sara Ahmed",
    message: "Complete your assignment",
    grade: "1",
    section: "B",
  },
  {
    id: 2,
    studentName: "Abdellah Abdulatif Jemal",
    message: "Attend the class meeting",
    grade: "1",
    section: "B",
  },
  {
    id: 3,
    studentName: "Ahafiz Ali Yusuf",
    message: "Prepare for the test",
    grade: "2",
    section: "B",
  },
];

const Message = () => {
  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("B");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messages, setMessages] = useState(sampleMessages);

  //post
  const [messageCreatedRes, setMessageCreatedRes] = useState(false);
  const [messageCreateLoading, setMessageCreateLoading] = useState(false);
  const [messageCreateError, setMessageCreateError] = useState(false);

  const { post: createMessage, reset: resetCreateMessagePost } = usePost(
    "/api/message",
    {
      setLoading: setMessageCreateLoading,
      setError: setMessageCreateError,
      setData: setMessageCreatedRes,
    }
  );

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);

  const handleSendMessage = (messageData) => {
    setMessages([...messages, messageData]);
  };

  // Filter messages based on the selected grade and section
  const filteredMessages = useMemo(() => {
    return messages.filter(
      (msg) => msg.grade === selectedGrade && msg.section === selectedSection
    );
  }, [messages, selectedGrade, selectedSection]);

  return (
    <div id="Message">
      <h2>Message Page</h2>
      <Card title="Messages" bordered={false} style={{ margin: "20px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              defaultValue={selectedGrade}
              onChange={handleGradeChange}
              style={{ width: 200 }}
            >
              {grade.map((gra, index) => (
                <Option key={index} value={gra.val}>
                  {gra.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="Select Section"
              defaultValue={selectedSection}
              onChange={handleSectionChange}
              style={{ width: 200 }}
            >
              {section.map((sec, index) => (
                <Option key={index} value={sec}>
                  {sec}
                </Option>
              ))}
            </Select>
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add Message
              </Button>
            </div>
          </Col>
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={filteredMessages}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={`To: ${item.studentName}`}
                description={item.message}
              />
            </List.Item>
          )}
        />
        <Modal
          title="Send Message"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <MessageForm
            grade={selectedGrade}
            section={selectedSection}
            onSendMessage={(messageData) => {
              handleSendMessage(messageData);
              setIsModalVisible(false);
            }}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default Message;
