import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { generateColumns } from "../../utils/helper";

const DynamicTable = ({
  data = [{}],
  tableActions,
  loading,
  customColumns,
  scroll,
}) => {
  // Generate columns dynamically from data keys if customColumns is not provided

  const columns = customColumns || generateColumns(data, tableActions);

  return (
    <Table
      className="custom-scrollbar"
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={loading}
      scroll={{
        x: scroll?.x,
        y: 440,
      }}
    />
  );
};

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      confirm: PropTypes.bool,
      confirmMessage: PropTypes.string,
      danger: PropTypes.bool,
    })
  ).isRequired,
  customColumns: PropTypes.array,
  scroll: PropTypes.object, // Add scroll prop type
};

export default DynamicTable;
