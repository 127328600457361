import React, { useState, useEffect } from "react";
import { Input, Button, Upload, message, List, Avatar, Spin } from "antd";
import { UploadOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import "./AITraining.css";
import usePost from "../../../../hooks/usePost";
import useFetch from "../../../../hooks/useFetch";

const { TextArea } = Input;

const AITraining = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null); // New state for uploaded file

  // States for handling fetch response and error
  const [chatRes, setChatRes] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatError, setChatError] = useState(null);

  // States for file upload and messaging
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createdRes, setCreatedRes] = useState(null);

  // Fetch chat list from backend
  const { get: fetchChatList } = useFetch("/api/ai", {
    setLoading: setChatLoading,
    setError: setChatError,
    setData: setChatRes,
  });

  // Use custom hook to post data to the backend
  const { post: uploadFileAndMessage, reset: resetPost } = usePost("/api/ai", {
    setLoading: setCreateLoading,
    setError: setCreateError,
    setData: setCreatedRes,
  });

  useEffect(() => {
    fetchChatList();
  }, []);

  useEffect(() => {
    if (chatRes?.chats) {
      setMessages(chatRes.chats);
    }
  }, [chatRes]);

  useEffect(() => {
    if (createdRes?.message) {
      message[createdRes?.status](createdRes.message);
    }
    if (createdRes?.status === "success") {
      setMessages((prevMessages) => [...prevMessages, createdRes.data]);
    }
    resetPost();
  }, [createdRes]);

  useEffect(() => {
    if (createError?.message) {
      message.error(createError.message);
    }
    resetPost();
  }, [createError]);

  const handleUpload = (file) => {
    setUploadedFile(file); // Store the file but don't upload yet
    return false; // Prevent default upload behavior
  };

  const handleRemoveFile = () => {
    setUploadedFile(null); // Remove the file from state
  };

  const handleSend = async () => {
    if (inputValue.trim() === "" && !uploadedFile) {
      message.warning("Please enter instructions or upload a file.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    if (uploadedFile) {
      formData.append("file", uploadedFile);
    }
    formData.append("instruction", inputValue);

    await uploadFileAndMessage(formData);

    setUploading(false);
    setInputValue(""); // Clear input after sending
    setUploadedFile(null); // Clear the uploaded file after sending
  };

  return (
    <div className="ai-training-container">
      <h2>AI Training Chat</h2>

      {/* Chat Window */}
      <div className="chat-window">
        <List
          dataSource={messages}
          renderItem={(msg) => (
            <List.Item className="chat-message">
              <List.Item.Meta
                avatar={
                  <Avatar style={{ backgroundColor: "#1890ff" }}>A</Avatar>
                }
                description={
                  <>
                    {msg.type === "file" ? (
                      <div>
                        <p>
                          <strong>Uploaded File:</strong>
                        </p>
                        <a
                          href={msg.fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {msg.fileName}
                        </a>
                      </div>
                    ) : (
                      <p>{msg.instruction}</p>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />

        {uploading && <Spin tip="Uploading..." className="uploading-spinner" />}
        {chatLoading && <Spin tip="Loading chats..." />}
      </div>

      {/* Display uploaded file with option to remove */}
      {uploadedFile && (
        <div className="uploaded-file-preview">
          <span>{uploadedFile.name}</span>
          <Button
            type="link"
            icon={<CloseOutlined />}
            onClick={handleRemoveFile}
            danger
          />
        </div>
      )}

      {/* Input Section */}
      <div className="chat-input-section">
        <Upload beforeUpload={handleUpload} showUploadList={false}>
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
        <TextArea
          rows={1}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type instructions here..."
          className="chat-input"
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSend}
          className="send-button"
          disabled={uploading || createLoading}
        />
      </div>
    </div>
  );
};

export default AITraining;
