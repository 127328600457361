import React, { useState } from "react";
import { Layout, theme } from "antd";

import Headers from "../../../components/header/Header";

import AdminQuestionRoute from "../../../router/routes/AdminQuestionRoute";
import AdminQuestionSider from "../../../components/sidebar/admin-question-sidebar/AdminQuestionSidebar";
const { Content } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const AdminQuestionPage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <AdminQuestionSider />
      <Layout>
        <Headers />

        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: "95vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            marginTop: "30px",
          }}
        >
          <AdminQuestionRoute />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminQuestionPage;
