import React, { useEffect, useState } from "react";

import { FaCheck } from "react-icons/fa6";
import "./Auth.css";
import AuthHandler from "../../components/authHandler/AuthHandler";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
function Auth({ role }) {
  const navigate = useNavigate();

  const [authRole, setAuthRole] = useState(role);

  console.log("role.............", role, authRole);
  // Authentication state variables
  const { authRes, authLoading, authError, getAuth } = useAuth();

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    setAuthRole(role);
  }, [role]);

  useEffect(() => {
    console.log("-----------------------authRes", authRes);

    if (!authRes) return;
    if (!authRes?.isSchoolVerified) return setAuthRole("school");
    if (!authRes?.isUserVerified) return setAuthRole("user");
    if (authRes?.role) navigate("/app/" + authRes?.role);
  }, [authRes]);

  useEffect(() => {
    console.log("-----------------------authRes", authError);
  }, [authError]);

  //If an authenticated user went to change the school to go SchoolAuth Page
  return (
    <>
      <div id="Auth">
        <AuthHandler authLoading={authLoading} authRes={authRes} />

        <div className="main-nav" id="main-nav">
          <div className="container main-nav-container">
            <div
              className="logo-container"
              onClick={() => {
                console.log("clicked");
                navigate("/");
              }}
            >
              <img src="../../../logo.svg" alt="" />
              <a className="logo">
                <span>TOFI</span>
              </a>
            </div>
          </div>
        </div>
        <header id="showcase" className="showcase header">
          <div className="container">
            <div className="showcase-container">
              <div className="showcase-content">
                <h1>Integrated School Management Hub</h1>
                <p>
                  <FaCheck /> Execute tasks seamlessly within the browser and
                  native apps. <br />
                  <FaCheck /> Collaborate instantly through live, multiplayer
                  features. <br />
                  <FaCheck /> Utilize AI to enhance overall school management
                  efficiency. <br />
                  <FaCheck /> Join an active network of engaged school
                  professionals.
                </p>
              </div>
              <div className="login-form">
                {authRole === "school" && navigate("/auth/school")}
                {authRole === "user" && navigate("/auth/login")}
                {/* {userAuthStatus?.schoolVerified ? <Login /> : <SchoolCode />} */}
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Auth;
