import React, { useState } from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import { AiFillFileText, AiOutlineHistory } from "react-icons/ai";
const { Sider } = Layout;
export const adminMenuItems = [
  {
    key: "feedback",
    icon: <AiFillFileText />,
    label: "Feedback Analysis",
    href: "admin-analysis",
  },
  {
    key: "questionHistory",
    icon: <AiOutlineHistory />,
    label: "Question History",
    href: "question-history",
  },
  {
    key: "aiTraining",
    icon: <AiOutlineHistory />,
    label: "AI Training",
    href: "ai-training",
  },
];
function AdminQuestionSider() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const items = adminMenuItems.map((menuItem, i) => ({
    key: i,
    icon: menuItem.icon,
    label: (
      <Link to={`/app/admin-ai-analysis/${menuItem.href}`}>
        {menuItem.label}
      </Link>
    ),
  }));

  return (
    <Sider
      className="sider-comp"
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth="0"
    >
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
    </Sider>
  );
}

export default AdminQuestionSider;
