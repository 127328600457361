import React, { useState } from "react";
import { Form, Input, InputNumber, DatePicker, Select, Button } from "antd";
// import styles from "./PassengerPaymentForm.module.css"; // Assuming you're using CSS modules

const { Option } = Select;

const PaymentForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [netAmount, setNetAmount] = useState(0);

  // Get the current year
  const currentYear = new Date().getFullYear();

  const handleAmountChange = (value) => {
    setGrossAmount(value);
    const tax = (value * taxPercentage) / 100;
    const net = value - tax;
    setTaxAmount(tax);
    setNetAmount(net);
  };

  const handleTaxPercentageChange = (value) => {
    setTaxPercentage(value);
    const tax = (grossAmount * value) / 100;
    const net = grossAmount - tax;
    setTaxAmount(tax);
    setNetAmount(net);
  };

  const onFinish = (values) => {
    onCreate({
      ...values,
      taxAmount,
      netAmount,
      totalPaymentAmount: grossAmount,
    });
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="student_payment_form"
      onFinish={onFinish}
    >
      <Form.Item
        name="studentSearch"
        label="Search Student"
        rules={[{ required: true, message: "Please search for a student!" }]}
      >
        <Input placeholder="Search student" />
      </Form.Item>

      <Form.Item
        name="paymentReason"
        label="Payment Reason"
        rules={[
          { required: true, message: "Please select the payment reason!" },
        ]}
      >
        <Select placeholder="Select payment reason">
          <Option value="monthly_payment">Monthly Payment</Option>
          <Option value="registration">Registration</Option>
          <Option value="transportation">Transportation</Option>
          <Option value="tutorial">Tutorial</Option>
          <Option value="paynality">Paynality</Option>
          <Option value="books">Books</Option>

          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="amount"
        label="Amount"
        rules={[{ required: true, message: "Please input the amount!" }]}
      >
        <InputNumber
          min={0}
          placeholder="Enter amount"
          onChange={handleAmountChange}
        />
      </Form.Item>

      <Form.Item
        name="paymentMethod"
        label="Payment Method"
        rules={[
          { required: true, message: "Please select the payment method!" },
        ]}
      >
        <Select placeholder="Select payment method">
          <Option value="cash">Cash</Option>
          <Option value="credit card">Credit Card</Option>
          <Option value="mobile payment">Mobile Payment</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="month"
        label="Month"
        rules={[{ required: true, message: "Please select the month!" }]}
      >
        <Select placeholder="Select a month">
          <Option value="January">January</Option>
          <Option value="February">February</Option>
          <Option value="March">March</Option>
          {/* Add more months as needed */}
        </Select>
      </Form.Item>

      <Form.Item
        name="year"
        label="Payed for year"
        initialValue={currentYear}
        rules={[{ required: true, message: "Please select the year!" }]}
      >
        <Select>
          <Option value={currentYear}>{currentYear}</Option>
          <Option value={currentYear - 1}>{currentYear - 1}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="payedBy"
        label="Paid By"
        rules={[{ required: true, message: "Please enter the payer name!" }]}
      >
        <Input placeholder="Paid by ..." />
      </Form.Item>

      <Form.Item
        name="tinNumber"
        label="TIN Number"
        rules={[{ required: false, message: "Please enter the TIN number!" }]}
      >
        <Input placeholder="Enter TIN number" />
      </Form.Item>

      <Form.Item
        name="paymentDescription"
        label="Payment Description"
        rules={[{ required: true, message: "Please enter a description!" }]}
      >
        <Input placeholder="Enter description" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add Payment
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PaymentForm;
