import React, { useState, useMemo, useEffect } from "react";
import { Card, Button, Modal, Form, message } from "antd";

import TeacherTaskForm from "../../forms/teacher-task-form/TeacherTaskForm";
import TaskTable from "../../tables/teacher-task-table/TeacherTaskTable";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import useDelete from "../../../hooks/useDelete";

const TeacherTask = () => {
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [task, setTask] = useState([]);
  const [searchText, setSearchText] = useState("");

  // use states for post
  const [taskCreatedRes, setTaskCreatedRes] = useState(null);
  const [taskCreateLoading, setTaskCreatedLoading] = useState(false);
  const [taskCreateError, setTaskCreatedError] = useState(false);

  // Refactored useFetch for teacher Task
  const [tasks, setTasks] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskFetchError, setTaskFetchError] = useState(null);

  const [taskDeleteRes, setTaskDeleteRes] = useState(null);
  const [taskDeleteLoading, setTaskDeleteLoading] = useState(false);
  const [taskDeleteError, setTaskDeleteError] = useState(null);

  const [teachers, setTeachers] = useState(null);
  const [teachersLoading, setTeachersLoading] = useState(false);
  const [teachersFetchError, setTeachersFetchError] = useState(null);

  const { remove: deleteSelectedTask, reset: resetDeleteTaskPost } = useDelete(
    "/api/admin/teachersTask/delete",
    {
      setLoading: setTaskDeleteLoading,
      setError: setTaskDeleteError,
      setData: setTaskDeleteRes,
    }
  );

  const { post: createTask, reset: resetCreateTaskPost } = usePost(
    "/api/admin/teachersTask",
    {
      setLoading: setTaskCreatedLoading,
      setError: setTaskCreatedError,
      setData: setTaskCreatedRes,
    }
  );

  const { get: fetchTask } = useFetch("/api/admin/teachersTask", {
    setLoading: setTaskLoading,
    setError: setTaskFetchError,
    setData: setTasks,
  });

  const { get: fetchTeachers } = useFetch("/api/admin/teachersTask/teachers", {
    setLoading: setTeachersLoading,
    setError: setTeachersFetchError,
    setData: setTeachers,
  });

  useEffect(() => {
    fetchTask();
  }, []);

  const handleAddTasks = () => {
    setIsModalVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddOk = async () => {
    const values = await addForm.validateFields();
    const selectedTeacher = teachers.find(
      (teacher) => teacher.userId === values.teacherId
    );

    values.teacherName = selectedTeacher.fullName;

    console.log("here are the values:", values);

    await createTask(values);
  };

  useEffect(() => {
    if (taskCreatedRes?.message)
      message[taskCreatedRes?.status || "success"](taskCreatedRes?.message);

    if (taskCreatedRes?.status === "success") {
      setIsModalVisible(false);

      form.resetFields();
      resetCreateTaskPost();
      fetchTask();
    }
  }, [taskCreatedRes]);

  useEffect(() => {
    if (taskCreateError?.message)
      message[taskCreateError?.status || "error"](taskCreateError?.message);
    resetCreateTaskPost();
  }, [taskCreateError]);

  const deleteTask = (record) => {
    console.log(record);
    deleteSelectedTask(record.id);
  };

  useEffect(() => {
    if (taskDeleteRes?.message)
      message[taskDeleteRes?.status || "success"](taskDeleteRes?.message);

    if (taskDeleteRes?.status === "success") {
      resetDeleteTaskPost();
      fetchTask();
    }
  }, [taskDeleteRes]);

  useEffect(() => {
    if (taskDeleteError?.message)
      message[taskDeleteError?.status || "error"](taskDeleteError?.message);
    resetCreateTaskPost();
  }, [taskDeleteError]);

  return (
    <div id="TeacherTask">
      <h2>Teacher Task Management</h2>
      <Card bordered={false} style={{ margin: "20px" }}>
        <div style={{ float: "right", marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={handleAddTasks}
            style={{ marginLeft: 10 }}
            loading={taskLoading}
          >
            Add New Task
          </Button>
        </div>
        <TaskTable
          taskData={tasks}
          setModalClosed={showModal}
          taskLoading={taskLoading || taskDeleteLoading}
          deleteTask={deleteTask}
        />

        <Modal
          title="Add New Task"
          onOk={handleAddOk}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          confirmLoading={taskCreateLoading}
        >
          <TeacherTaskForm
            form={addForm}
            fetchTeachers={fetchTeachers}
            teachers={teachers}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default TeacherTask;
