import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Select,
  Popconfirm,
  Button,
  Row,
  message,
  Col,
  Card,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import StaffTable, {
  excludedStaffCols,
} from "../../tables/staff-table/StaffsTable";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import StaffRegForm from "../../forms/staff-reg-form/StaffRegForm";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";

function StaffRegistration() {
  const [form] = Form.useForm();
  const [modalClosed, setModalClosed] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedColumn, setSelectedColumn] = useState(excludedStaffCols[0]);
  const [editingStaff, setEditingStaff] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [current, setCurrent] = useState(0);

  // Post operation states
  const [staffCreatedRes, setStaffCreatedRes] = useState(false);
  const [staffCreateLoading, setStaffCreateLoading] = useState(false);
  const [staffCreateError, setStaffCreateError] = useState(false);

  // Patch operation states
  const [staffModifyRes, setStaffModifyRes] = useState(false);
  const [staffModifyLoading, setStaffModifyLoading] = useState(false);
  const [staffModifyError, setStaffModifyError] = useState(false);

  // Fetch operation states
  const [staffFetchLoading, setStaffFetchLoading] = useState(false);
  const [staffFetchError, setStaffFetchError] = useState(false);
  const [staffData, setStaffData] = useState([]);

  // Fetch staff data
  const { get: fetchStaffs } = useFetch("/api/user", {
    setLoading: setStaffFetchLoading,
    setError: setStaffFetchError,
    setData: setStaffData,
  });

  // Create staff
  const { post: createStaff, reset: resetCreateStaffPost } = usePost(
    "/api/user",
    {
      setLoading: setStaffCreateLoading,
      setError: setStaffCreateError,
      setData: setStaffCreatedRes,
    }
  );

  // Modify staff
  const { patch: modifyStaff, reset: resetStaffModify } = usePatch(
    `/api/user/${editingStaff?.id}`,
    {
      setLoading: setStaffModifyLoading,
      setError: setStaffModifyError,
      setData: setStaffModifyRes,
    }
  );

  useEffect(() => {
    fetchStaffs(); // Fetch staff data on component mount
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const filteredData = useMemo(() => {
    return staffData?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [staffData, searchText, selectedColumn]);

  // Handle create staff
  const handleCreate = async () => {
    try {
      const values = form.getFieldValue();
      await form.validateFields();
      await createStaff(values);
    } catch (error) {
      console.log(error);
      message.error("Failed to create User");
    }
  };

  // Handle edit staff
  const handleEdit = (value) => {
    setEditingStaff(value);
    setIsEditModalVisible(true);
  };

  // Handle update staff
  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldValue();
      await modifyStaff(values);
    } catch (error) {
      console.error(error);
      message.error("Failed to update user");
    }
  };

  useEffect(() => {
    if (staffCreatedRes?.message && staffCreatedRes?.status)
      message?.[staffCreatedRes.status](staffCreatedRes.message);
    if (staffCreatedRes?.message === "created") {
      fetchStaffs();
      setModalClosed(true);
    }
    resetCreateStaffPost();
  }, [staffCreatedRes]);

  useEffect(() => {
    if (staffCreateError?.message) message.error(staffCreateError.message);
    resetCreateStaffPost();
  }, [staffCreateError]);

  useEffect(() => {
    if (staffModifyRes?.message && staffModifyRes?.status)
      message?.[staffModifyRes.status](staffModifyRes.message);
    if (staffModifyRes?.message === "updated") {
      fetchStaffs();
      setIsEditModalVisible(false);
    }
    resetStaffModify();
  }, [staffModifyRes]);

  useEffect(() => {
    if (staffModifyError?.message) message.error(staffModifyError.message);
    resetStaffModify();
  }, [staffModifyError]);

  const columnOptions = excludedStaffCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="StaffRegistration">
      <div className="header">
        <h2>Staff Registration</h2>

        <Breadcrumb />
      </div>
      <Card title="Add New Staff" bordered={false} style={{ margin: "20px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          {contextHolder}
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              options={columnOptions}
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
            />
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalClosed(false);
                  setEditingStaff(false);
                }}
              >
                Add Staff
              </Button>
            </div>
          </Col>
        </Row>

        <StaffTable
          filteredData={filteredData}
          staffLoading={staffCreateLoading}
        />
        {editingStaff && (
          <Modal
            title="Edit Staff"
            open={isEditModalVisible}
            onOk={handleUpdate}
            onCancel={() => setIsEditModalVisible(false)}
          >
            <StaffRegForm
              form={form}
              current={current}
              setCurrent={setCurrent}
              editingStaff={editingStaff}
            />
          </Modal>
        )}
        <Modal
          title="Add New Staff"
          open={!modalClosed}
          onOk={handleCreate}
          onCancel={() => setModalClosed(true)}
        >
          <StaffRegForm
            form={form}
            current={current}
            setCurrent={setCurrent}
            editingStaff={editingStaff}
          />
        </Modal>
      </Card>
    </div>
  );
}

export default StaffRegistration;
