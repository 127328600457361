import React from "react";
import { Form, Input, DatePicker } from "antd";
import moment from "moment";

const EditTermForm = ({ form, selectedTerm }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        name: selectedTerm?.name,
        code: selectedTerm?.code,
        startDate: selectedTerm?.startDate
          ? moment(selectedTerm.startDate)
          : null,
        endDate: selectedTerm?.endDate ? moment(selectedTerm.endDate) : null,
        order: selectedTerm?.order,
      }}
    >
      <Form.Item
        label="Term Name"
        name="name"
        rules={[{ required: true, message: "Please input the term name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "Please input the term code!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Start Date"
        name="startDate"
        rules={[{ required: true, message: "Please input the start date!" }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="End Date"
        name="endDate"
        rules={[{ required: true, message: "Please input the end date!" }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Order"
        name="order"
        rules={[{ required: true, message: "Please input the term order!" }]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  );
};

export default EditTermForm;
