import React from "react";
import LayoutComponent from "../../../components/layout/Layout";
import TeacherRoutes from "../../../router/routes/TeacherRoute";

const Teacher = () => {
  return (
    <LayoutComponent>
      <TeacherRoutes />
    </LayoutComponent>
  );
};

export default Teacher;
