import React, { useState, useMemo } from "react";
import { Table, Select, Card, Row, Col } from "antd";
import { grade, section } from "../../../services/data";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import AttendanceTable from "../../tables/attendance-table/AttendanceTable";
import DynamicTable from "../../tables/DynamicTable";
import "./Attendance.css";
import { useEffect } from "react";

const { Option } = Select;

const attendanceData = [
  {
    id: "St-16-O-A-H#Bc264",
    name: "Ahafiz Ali Yusuf",
    sex: "male",
    grade: "2",
    section: "B",
    attendance: Array(30).fill(""), // Example attendance data
  },
  {
    id: "St-16-O-A-H#Bc268",
    name: "Abdellah Abdulatif Jemal",
    sex: "male",
    grade: "1",
    section: "B",
    attendance: Array(30).fill(""), // Example attendance data
  },
  {
    id: "St-21-1-B-H#Bc270",
    name: "Sara Ahmed",
    sex: "female",
    grade: "1",
    section: "A",
    attendance: Array(30).fill(""), // Example attendance data
  },
  // Add more sample data as needed
];

// const generateColumns = (data) => {
//   if (!data || data.length === 0) return [];

//   const dynamicColumns = [
//     {
//       title: "Student Info",
//       key: "studentInfo",
//       render: (text, record) => (
//         <>
//           <div className="tableSubCols heading">
//             {record.name.toUpperCase()}
//           </div>
//           <div className="tableSubCols details">ID: {record.id}</div>
//           <div className="tableSubCols details">Sex: {record.sex}</div>
//         </>
//       ),
//     },
//   ];

//   const attendanceColumns = Array.from({ length: 30 }, (_, index) => ({
//     title: (index + 1).toString(),
//     dataIndex: ["attendance", index],
//     key: `attendance-${index + 1}`,
//   }));

//   return [...dynamicColumns, ...attendanceColumns];
// };

const Attendance = () => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("B");

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);

  // const columns = useMemo(() => generateColumns(attendanceData), []);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  // Filter data based on selected grade and section
  const filteredData = useMemo(() => {
    return attendanceData.filter((item) => {
      return (
        (!selectedGrade || item.grade === selectedGrade) &&
        (!selectedSection || item.section === selectedSection)
      );
    });
  }, [selectedGrade, selectedSection]);

  return (
    <div id="Attendance">
      <h2>Attendance Page</h2>
      <Card bordered={false} style={{ margin: "20px" }}>
        <div className="attendance-page">
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Select
                defaultValue={selectedGrade}
                onChange={handleGradeChange}
                style={{ width: 200 }}
              >
                {gradeDivisions.map((gra, index) => (
                  <Option key={index} value={gra.val}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Section"
                defaultValue={selectedSection}
                onChange={handleSectionChange}
                style={{ width: 200 }}
              >
                {section.map((sec, index) => (
                  <Option key={index} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          {/* <DynamicTable
            data={filteredData}
            customColumns={columns}
            scroll={{ x: 3800 }}
          /> */}
          <AttendanceTable attendanceData={filteredData} />
        </div>
      </Card>
    </div>
  );
};

export default Attendance;
