// src/components/MarkList.js

import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { grade, section, subject } from "../../../services/data"; // Markuming section and subject data are available
import {
  Table,
  Select,
  Card,
  Row,
  Col,
  Input,
  Form,
  Button,
  Popconfirm,
} from "antd";
import AssessmentTable from "../../tables/assessment-list-table/AssessmentListTable";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import useDelete from "../../../hooks/useDelete";
import "./Assessment.css";

const { Option } = Select;
const EditableContext = React.createContext(null);

// Sample data (moved inside the component scope)
const sampleData = [
  {
    id: "St-16-O-A-H#Bc264",
    name: "Ahafiz Ali Yusuf",
    sex: "male",
    grade: "Nursery",
    section: "A",
    subject: "Math",
    W1: 5,
    T1: 8,
    Mid_1: 12,
    Mid_2: 12,
    W2: 5,
    T2: 10,
    CI_Acct: 4,
    Proj: 5,
    CH_W: 5,
    Ex_B: 14,
    Final: 19,
    Total70: 58,
    Total100: 72,
  },
  // ...more sample data
];

// Generate Columns (moved inside the component scope)
const generateColumns = () => [
  {
    title: "Id",
    key: "id",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">{record.name.toUpperCase()}</div>
        <div className="tableSubCols details">ID: {record.id}</div>
        <div className="tableSubCols details">Sex: {record.sex}</div>
      </>
    ),
  },
  {
    title: "W.1 (5%)",
    key: "W1",
    dataIndex: "W1",
    editable: true,
  },
  {
    title: "T.1 (8%)",
    key: "T1",
    dataIndex: "T1",
    editable: true,
  },
  {
    title: "Mid 1 (15%)",
    key: "Mid_1",
    dataIndex: "Mid_1",
    editable: true,
  },
  {
    title: "Mid 2 (20%)",
    key: "Mid_2",
    dataIndex: "Mid_2",
    editable: true,
  },
  {
    title: "Final (50%)",
    key: "Final",
    dataIndex: "Final",
    editable: true,
  },
  // ...other columns
];

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex); // Pass dataIndex to handleSave
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingInlineEnd: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Assessments = () => {
  const [dataSource, setDataSource] = useState(sampleData);
  const [selectedGrade, setSelectedGrade] = useState("Nursery");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  // State for assessment fetching
  const [assessments, setAssessments] = useState([]);
  const [assLoading, setAssLoading] = useState(false);
  const [fetchAssError, setFetchAssError] = useState(false);

  // Fetch assessments using useFetch
  const { get: fetchAss } = useFetch("/api/assessment", {
    setLoading: setAssLoading,
    setError: setFetchAssError,
    setData: setAssessments,
  });

  // State for CRUD operations
  const [editingMark, setEditingMark] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  // Post
  const [markCreatedRes, setMarkCreatedRes] = useState(false);
  const [markCreateLoading, setMarkCreateLoading] = useState(false);
  const [markCreateError, setMarkCreateError] = useState(false);

  // Patch
  const [markModifyRes, setMarkModifyRes] = useState(false);
  const [markModifyLoading, setMarkModifyLoading] = useState(false);
  const [markModifyError, setMarkModifyError] = useState(false);

  // Delete
  const [markDeleteLoading, setMarkDeleteLoading] = useState(false);
  const [markDeleteError, setMarkDeleteError] = useState(false);
  const [markDeleteRes, setMarkDeleteRes] = useState(false);

  // Use Effect to fetch assessments
  useEffect(() => {
    // fetchAss(); // Fetch assessments on component mount
  }, [fetchAss]);

  const { post: createMark, reset: resetCreateMarkPost } = usePost(
    "/api/mark_list",
    {
      setLoading: setMarkCreateLoading,
      setError: setMarkCreateError,
      setData: setMarkCreatedRes,
    }
  );

  const { patch: modifyMark, reset: resetMarkModify } = usePatch(
    `/api/mark_list/${editingMark?.id}`,
    {
      setLoading: setMarkModifyLoading,
      setError: setMarkModifyError,
      setData: setMarkModifyRes,
    }
  );

  const { remove: removeMark, reset: resetMarkRemove } = useDelete(
    `/api/mark_list`,
    {
      setLoading: setMarkDeleteLoading,
      setError: setMarkDeleteError,
      setData: setMarkDeleteRes,
    }
  );

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);
  const handleSubjectChange = (val) => setSelectedSubject(val);
  const handleSave = (row, changedField) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];

    // Log only the last edited cell with name, sex, and id
    console.log({
      id: row.id,
      name: row.name,
      sex: row.sex,
      changedCell: {
        field: changedField,
        newValue: row[changedField],
      },
    });

    // Update the dataSource
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.id !== key);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData = {
      id: `St-${Math.random().toString(36).substr(2, 9)}`,
      name: `New Student`,
      sex: "male",
      grade: selectedGrade,
      section: selectedSection,
      subject: selectedSubject,
      W1: 0,
      T1: 0,
      Mid_1: 0,
      Mid_2: 0,
      W2: 0,
      T2: 0,
      CI_Acct: 0,
      Proj: 0,
      CH_W: 0,
      Ex_B: 0,
      Final: 0,
      Total70: 0,
      Total100: 0,
    };
    setDataSource([...dataSource, newData]);
  };

  const columns = generateColumns();

  return (
    <div>
      <Card title="Mark List" bordered={false}>
        <Row gutter={16}>
          <Col span={8}>
            <Select
              defaultValue={selectedGrade}
              onChange={handleGradeChange}
              style={{ width: "100%" }}
            >
              {grade.map((g) => (
                <Option key={g.value} value={g.value}>
                  {g.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              defaultValue={selectedSection}
              onChange={handleSectionChange}
              style={{ width: "100%" }}
            >
              {section.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              defaultValue={selectedSubject}
              onChange={handleSubjectChange}
              style={{ width: "100%" }}
            >
              {subject.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          bordered
          loading={assLoading}
          dataSource={dataSource}
          columns={columns.map((col) => ({
            ...col,
            onCell: (record) => ({
              record,
              editable: col.editable,
              title: col.title,
              dataIndex: col.dataIndex,
              handleSave,
            }),
          }))}
          rowKey="id"
          pagination={false}
        />
        <Button type="primary" onClick={handleAdd} style={{ marginTop: 16 }}>
          Add Student
        </Button>
      </Card>
    </div>
  );
};

export default Assessments;
