import { useState } from "react";
import useFetch from "./useFetch";

const useFetchTerms = () => {
  const [terms, setTerms] = useState([]);
  const [fetchTermsLoading, setFetchTermsLoading] = useState(false);
  const [fetchTermsError, setFetchTermsError] = useState(null);

  const { get: fetchTerms } = useFetch("api/terms", {
    setLoading: setFetchTermsLoading,
    setError: setFetchTermsError,
    setData: setTerms,
  });

  // Return state and fetch function
  return {
    terms,
    fetchTermsLoading,
    fetchTermsError,
    fetchTerms, // Expose fetch function for manual triggering
  };
};

export default useFetchTerms;
