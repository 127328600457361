import React from "react";
import LayoutComponent from "../../../components/layout/Layout";
import AdminRoute from "../../../router/routes/AdminRoute";

const Administration = () => {
  return (
    <LayoutComponent>
      <AdminRoute />
    </LayoutComponent>
  );
};

export default Administration;
