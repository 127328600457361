import React, { useState, useEffect } from "react";
import "./Login.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Anchor,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Popconfirm,
} from "antd";
import usePost from "../../hooks/usePost";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import AuthHandler from "../authHandler/AuthHandler";
import useAuth from "../../hooks/useAuth";

function Login() {
  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginRes, setLoginRes] = useState(null);

  const { post: sendLoginData, reset: resetLoginPost } = usePost(
    "/auth/login",
    {
      setLoading: setLoginLoading,
      setError: setLoginError,
      setData: setLoginRes,
    }
  );

  const onFinish = (values) => {
    console.log("post data:", values);
    values.email = values?.email?.trim();
    sendLoginData(values);
  };

  useEffect(() => {
    console.log("-----------------------res", loginRes);

    if (loginRes?.message) message.success(loginRes?.message);

    if (loginRes?.status === "success" && loginRes?.token) {
      localStorage.setItem(`userAccessToken`, loginRes.token);

      if (loginRes?.school?.schoolName)
        localStorage.setItem(`schoolName`, loginRes.school.schoolName);

      if (loginRes?.school?.detail?.logo) {
        console.log(loginRes.school.detail.logo);
        localStorage.setItem(`schoolLogo`, loginRes.school.detail.logo);
      }
      if (loginRes?.user?.role) {
        console.log(loginRes?.user?.role);
        localStorage.setItem(`userRole`, loginRes?.user?.role);
      }

      navigate("/app/" + loginRes?.user.role);
    }
    resetLoginPost();
  }, [loginRes]);

  useEffect(() => {
    console.log("-----------------------res", loginRes);

    if (loginError) message.error(loginError.message);

    resetLoginPost();
  }, [loginError]);

  const handleSchoolForget = () => {
    localStorage.setItem("schoolAccessToken", null);
    window.location.href = "/auth/school";
  };

  return (
    <>
      <div id="Auth">
        <div className="main-nav" id="main-nav">
          <div className="container main-nav-container">
            <div
              className="logo-container"
              onClick={() => {
                console.log("clicked");
                navigate("/");
              }}
            >
              <img src="../../../logo.svg" alt="" />
              <a className="logo">
                <span>TOFI</span>
              </a>
            </div>
          </div>
        </div>
        <header id="showcase" className="showcase header">
          <div className="container">
            <div className="showcase-container">
              <div className="showcase-content">
                <h1>Integrated School Management Hub</h1>
                <p>
                  <FaCheck /> Execute tasks seamlessly within the browser and
                  native apps. <br />
                  <FaCheck /> Collaborate instantly through live, multiplayer
                  features. <br />
                  <FaCheck /> Utilize AI to enhance overall school management
                  efficiency. <br />
                  <FaCheck /> Join an active network of engaged school
                  professionals.
                </p>
              </div>
              <div className="login-form">
                <div id="Login">
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                  >
                    <h1>Login</h1>
                    <Form.Item
                      name="email"
                      className="form-item"
                      rules={[
                        { required: true, message: "Please input your email!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <div style={{ display: "flex", width: "100%" }}>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                          Forgot password
                        </a>
                        <Popconfirm
                          title="Are you sure!"
                          onConfirm={handleSchoolForget}
                          style={{ fontSize: 10 }}
                        >
                          <Button>Forget School</Button>
                        </Popconfirm>
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={loginLoading}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Login;
