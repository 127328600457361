import { jsPDF } from "jspdf";
import { message } from "antd";
import { idFont as font } from "./fonts.js";

export const printStudId = (students, imgUri) => {
  if (students.length === 0) {
    message.warning("No students selected for ID generation.");
    return;
  }

  if (!imgUri || students.length === 0) return; // Wait for image

  const doc = new jsPDF("p", "pt", "a4");
  let num_of_ids_per_page = 0;
  // Draw the background ID template
  doc.addImage(
    imgUri,
    "JPEG",
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height
  );

  students.forEach((student, index) => {
    // Start new page if 8 IDs have already been added to the current page
    if (num_of_ids_per_page >= 8) {
      num_of_ids_per_page = 0;
      doc.addPage();
    }
    num_of_ids_per_page++;
    doc.setFontSize(7);

    // Determine position_x and position_y based on the ID's place on the page
    let position_x = 0;
    let position_y = 0;

    doc.addFileToVFS("MeriendaOne-Regular-normal.ttf", font());
    doc.addFont(
      "MeriendaOne-Regular-normal.ttf",
      "MeriendaOne-Regular",
      "normal"
    );

    // doc.setFontStyle("normal");
    doc.setFont("MeriendaOne-Regular");
    doc.setTextColor(40);

    if (num_of_ids_per_page == 2) position_x = 284;

    if (num_of_ids_per_page == 4) position_x = 284;

    if (num_of_ids_per_page == 6) position_x = 284;

    if (num_of_ids_per_page == 8) position_x = 284;

    if (num_of_ids_per_page == 3 || num_of_ids_per_page == 4) position_y = 188;

    if (num_of_ids_per_page == 5 || num_of_ids_per_page == 6)
      position_y = 188 + 188.7;

    if (num_of_ids_per_page == 7 || num_of_ids_per_page == 8)
      position_y = 188 + 188.7 + 188.2;

    // ID card layout for each student
    console.log(
      "position with index",
      position_x,
      index,
      num_of_ids_per_page,
      student["STUD ID"],
      59 + position_x
    );

    doc.text(` ${student["STUD ID"]}`, 59 + position_x, 114 + position_y);
    doc.text(`${student["GRADE"]}`, 160 + position_x, 114 + position_y);

    doc.text(
      ` ${student["FULL NAME"]}`,
      240 + position_x,
      151 + position_y,
      "center"
    );
    // doc.text(`${student["GRADE"]}`, 66 + position_x, 126.5 + position_y);
    doc.text(`1/1/24 - 30/10/24`, 65 + position_x, 126.5 + position_y);
    doc.text(` ${student["AGE"]}`, 53 + position_x, 140 + position_y);
    doc.text(` ${student["SEX"]}`, 150 + position_x, 140 + position_y);

    // doc.text(
    //   ` ${student["GRADE"]} - Section: ${student["SECTION"]}`,
    //   160.9 + position_x,
    //   114 + position_y
    // );
    // doc.text(` ${student["SEX"]}`, 150 + position_x, 140 + position_y);

    doc.setFontSize(5);
    // doc.text(
    //   `1/1/${student["YEAR"]} - 30/10/${student["YEAR"]}`,
    //   66 + position_x,
    //   126.5 + position_y
    // );

    doc.setFontSize(7);
    doc.text(` ${student["SECTION"]}`, 165 + position_x, 127.5 + position_y);
    doc.text(` ${student["PHONE"]}`, 65 + position_x, 152 + position_y);
    doc.text(
      `Roll Num: ${student["ROLL NUM"]}`,
      130 + position_x,
      152 + position_y
    );

    doc.text(` ${student["SUB CITY"]}`, 63 + position_x, 166 + position_y);
    doc.text(`${student["WEREDA"]}`, 150 + position_x, 165 + position_y);

    // Principal information (name and signature if available)
    doc.text(
      ` ${student["CREATED BY"]}`,
      201.7 + position_x,
      185.3 + position_y
    );
    if (student["PRINCIPAL_SIGN"]) {
      doc.addImage(
        student["PRINCIPAL_SIGN"],
        206.9 + position_x,
        182.3 + position_y,
        20,
        20
      );
    }

    // Image for the student if available
    // if (student["IMAGE"]) {
    //   doc.addImage(
    //     student["IMAGE"],
    //     210 + position_x,
    //     73.9 + position_y,
    //     63,
    //     60
    //   );
    // }
  });

  // Save the generated PDF
  console.log("generating ....................");

  doc.save(
    `${students[0]["GRADE"]} ${students[0]["SECTION"]} Students' ID Card.pdf`
  );
};
