import React, { useEffect, useRef } from "react";
import "./Chart.css";
import { Card } from "antd";
import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components
ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend);

const Chart = ({ chartData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  // Chart.js options
  const chartOptions = {
    type: "doughnut",
    data: {
      labels: chartData.labels, // Use labels directly from chartData
      datasets: chartData.datasets, // Use datasets directly from chartData
    },
    options: {
      responsive: true,
      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: true,
          position: "bottom",
        },
      },
      cutout: "70%",
    },
  };

  useEffect(() => {
    if (!chartData || !chartRef.current) return;

    const chartEl = chartRef.current.getContext("2d");

    // Destroy existing Chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    try {
      chartInstanceRef.current = new ChartJS(chartEl, chartOptions);
    } catch (err) {
      console.error("Error initializing chart:", err);
    }

    // Cleanup function to destroy Chart instance when component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData]); // Re-run effect when chartData changes

  return (
    <Card bordered={false}>
      <div className="chart-title">
        <h3>Summary Chart</h3>
      </div>
      <div className="chart-content">
        <canvas ref={chartRef} className="students-chart"></canvas>
      </div>
    </Card>
  );
};

export default Chart;
