import { useRoutes } from "react-router-dom";
import SchoolProfile from "../../components/setting-components/school-profile/SchoolProfile";
import Subject from "../../components/setting-components/subject/Subject";
import AssessmentList from "../../components/setting-components/assessment-list/AssessmentList";
import Assessments from "../../components/setting-components/assessments/Assessment";
import Attendance from "../../components/setting-components/attendance/Attendance";
import DashboardMain from "../../components/dashboard-main/DashboardMain";
import Announcement from "../../components/setting-components/announcement/Announcement";
import StudentId from "../../components/setting-components/id/StudentId";
import StaffId from "../../components/setting-components/id/StaffId";
import TeacherTask from "../../components/setting-components/teacher-task/TeacherTask";
import OrganizingStudents from "../../components/setting-components/organizing-students/OrganizingStudents";
import AcademicTerms from "../../components/setting-components/academic-terms/AcademicTerms";
import GradeDivisions from "../../components/setting-components/grade-divisions/GradeDivisions";
import QuestionHistory from "../../components/setting-components/question-history/QuestionHistory";
import TimeTable from "../../components/setting-components/time-table/TimeTable";
import StaffRegistration from "../../components/registration-form-container/staff-registration/StaffRegistration";
import StudentCertificate from "../../components/setting-components/student-certificate/StudentCertificate";
import StudentRegistration from "../../components/registration-form-container/student-registration/StudentRegistration";

const AdminRoute = () => {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardMain />,
    },
    {
      path: "/staff",
      element: <StaffRegistration />,
    },
    {
      path: "/students",
      element: <StudentRegistration />,
    },
    {
      path: "/profile",
      element: <SchoolProfile />,
    },
    {
      path: "/subject",
      element: <Subject />,
    },

    {
      path: "/subject",
      element: <Subject />,
    },
    {
      path: "/assessmentList",
      element: <AssessmentList />,
    },
    {
      path: "/assessments",
      element: <Assessments />,
    },
    {
      path: "/attendance",
      element: <Attendance />,
    },
    {
      path: "/announcement",
      element: <Announcement />,
    },
    {
      path: "/teacher-task",
      element: <TeacherTask />,
    },
    {
      path: "/student-id",
      element: <StudentId />,
    },
    {
      path: "/staff-id",
      element: <StaffId />,
    },
    {
      path: "/terms",
      element: <AcademicTerms />,
    },
    {
      path: "/gradeDivisions",
      element: <GradeDivisions />,
    },
    {
      path: "/organize",
      element: <OrganizingStudents />,
    },
    {
      path: "/history",
      element: <QuestionHistory />,
    },
    {
      path: "/time-table",
      element: <TimeTable />,
    },
    {
      path: "/certificate",
      element: <StudentCertificate />,
    },

    {
      path: "/*",
      element: <DashboardMain />,
    },
  ]);
  return routes;
};

export default AdminRoute;
