import React from "react";
import { Button } from "antd";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

const StudentIdTable = ({
  studentData,
  studentLoading,
  handleAddStudentForId,
}) => {
  const columns = [
    {
      title: "Info",
      key: "info",
      render: (_, record) => (
        <>
          <div>{record["FULL NAME"]?.toUpperCase()}</div>
          <div>Roll Num: {record["ROLL NUM"]}</div>
          <div>ID: {record["STUD ID"]}</div>
          <div>Sex: {record["SEX"]}</div>
        </>
      ),
    },
    {
      title: "Phone",
      key: "phone",
      render: (_, record) => (
        <>
          <div>Phone: {record["PHONE"]}</div>
          <div>Home: {record["PHONE HOME"]}</div>
        </>
      ),
    },
    {
      title: "Grade",
      key: "grade",
      render: (_, record) => (
        <>
          <div>Section: {record["SECTION"]}</div>
          <div>Grade: {record["GRADE"]}</div>
        </>
      ),
    },
    {
      title: "Region",
      key: "region",
      render: (_, record) => (
        <>
          <div>Region: {record["REGION"]}</div>
          <div>Sub City: {record["SUB CITY"]}</div>
          <div>Woreda: {record["WEREDA"]}</div>
          <div>Mother: {record["MOTHER NAME"]}</div>
        </>
      ),
    },
    {
      title: "Created",
      key: "created",
      render: (_, record) => (
        <>
          <div>{convertToDisplayDate(record["CREATED"])}</div>
          <div>Updated: {convertToDisplayDate(record["UPDATED"])}</div>
          <div>By: {record["CREATED BY"]}</div>
        </>
      ),
    },
    {
      title: "Generate ID",
      key: "generateId",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleAddStudentForId(record)}>
          Add
        </Button>
      ),
    },
  ];

  return (
    <DynamicTable
      data={studentData}
      loading={studentLoading}
      customColumns={columns}
      scroll={{ x: 1100 }}
    />
  );
};

export default StudentIdTable;
