import React, { useState, useMemo, useEffect } from "react";
import { Table, Select, Card, Row, Col, Button } from "antd";
import { grade, section } from "../../../services/data";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import useFetch from "../../../hooks/useFetch";
import { printTimeTable } from "../../../utils/printTimeTable";
const { Option } = Select;

const generateColumns = () => {
  const columns = [
    {
      title: "Days",
      dataIndex: "day",
      key: "day",
      render: (day) => <strong>{day}</strong>,
    },
  ];

  for (let i = 1; i <= 6; i++) {
    columns.push({
      title: `Period ${i}`,
      dataIndex: `period${i}`,
      key: `period${i}`,
      render: (text) => text || "-",
    });
  }

  return columns;
};

const TimeTable = () => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("A");
  const [timeTable, setTimeTable] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [timeTableLoading, setTimeTableLoading] = useState(false);
  const [timeTableFetchError, setTimeTableFetchError] = useState(null);

  const { get: fetchTimeTable } = useFetch("/api/time-table", {
    setLoading: setTimeTableLoading,
    setError: setTimeTableFetchError,
    setData: setTimeTable,
  });

  useEffect(() => {
    fetchTimeTable();
  }, []);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  console.log("the table data", formattedTableData);
  console.log("the time table", timeTable);

  useEffect(() => {
    // Filter and format timetable data
    const filteredData = formattedTableData.filter(
      (item) => item.grade === selectedGrade && item.section === selectedSection
    );

    const formattedData = filteredData.flatMap((item) =>
      item.timetable.map((dayItem) => {
        const row = { day: dayItem.day };

        dayItem.periods.forEach((period, index) => {
          row[`period${index + 1}`] = period || "-";
        });

        // console.log("...........", formattedData);
        return row;
      })
    );

    setFormattedTableData(formattedData);
  }, [timeTable, selectedGrade, selectedSection]);

  const columns = useMemo(() => generateColumns(), []);

  return (
    <div id="TimeTable">
      <h2>Time Table</h2>
      <Card title="Time Table" bordered={false} style={{ margin: "20px" }}>
        <div className="time-table-page">
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Select
                defaultValue={selectedGrade}
                onChange={setSelectedGrade}
                style={{ width: 200 }}
              >
                {gradeDivisions.map((gra, index) => (
                  <Option key={index} value={gra.name}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                defaultValue={selectedSection}
                onChange={setSelectedSection}
                style={{ width: 200 }}
              >
                {section.map((sec, index) => (
                  <Option key={index} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Table
            dataSource={formattedTableData}
            columns={columns}
            rowKey="day"
            bordered
            loading={timeTableLoading}
            scroll={{ x: 1200 }}
          />
          <Row justify="end" style={{ marginTop: 20 }}>
            <Button
              type="primary"
              onClick={() =>
                printTimeTable(
                  formattedTableData,
                  selectedGrade,
                  selectedSection
                )
              }
            >
              Download Timetable as PDF
            </Button>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default TimeTable;
