import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  message,
  Modal,
  Form,
  Card,
  Row,
  Col,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../../tables/DynamicTable";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch"; // Import your custom hook
import { grade, section } from "../../../services/data";
import AttendanceForm from "../../forms/attendance-form/AttendanceForm";
import "./Attendance.css";

const { Option } = Select;

const initialAttendanceData = [
  {
    id: "St-16-O-A-H#Bc264",
    name: "Ahafiz Ali Yusuf",
    sex: "male",
    grade: "2",
    section: "B",
    attendance: Array(30).fill(""),
  },
  {
    id: "St-16-O-A-H#Bc268",
    name: "Abdellah Abdulatif Jemal",
    sex: "male",
    grade: "1",
    section: "B",
    attendance: Array(30).fill(""),
  },
  {
    id: "St-21-1-B-H#Bc270",
    name: "Sara Ahmed",
    sex: "female",
    grade: "1",
    section: "A",
    attendance: Array(30).fill(""),
  },
];

const generateColumns = (data) => {
  if (!data || data.length === 0) return [];

  const dynamicColumns = [
    {
      title: "Student Info",
      key: "studentInfo",
      render: (text, record) => (
        <>
          <div className="tableSubCols heading">
            {record.name.toUpperCase()}
          </div>
          <div className="tableSubCols details">ID: {record.id}</div>
          <div className="tableSubCols details">Sex: {record.sex}</div>
        </>
      ),
    },
  ];

  const attendanceColumns = Array.from({ length: 30 }, (_, index) => ({
    title: (index + 1).toString(),
    dataIndex: ["attendance", index],
    key: `attendance-${index + 1}`,
  }));

  return [...dynamicColumns, ...attendanceColumns];
};

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState(initialAttendanceData);
  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("B");
  const [selectedDate, setSelectedDate] = useState(null);

  // Fetch state for marks
  const [marks, setMarks] = useState([]);
  const [markLoading, setMarkLoading] = useState(false);
  const [fetchMarkError, setFetchMarkError] = useState(null);

  const { get: fetchMark } = useFetch("/api/mark", {
    setLoading: setMarkLoading,
    setError: setFetchMarkError,
    setData: setMarks,
  });

  useEffect(() => {
    fetchMark();
  }, [selectedGrade]);

  // Post attendance
  const [attendanceCreatedRes, setAttendanceCreatedRes] = useState(false);
  const [attendanceCreateLoading, setAttendanceCreateLoading] = useState(false);
  const [attendanceCreateError, setAttendanceCreateError] = useState(false);

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);
  const handleDateChange = (date, dateString) => setSelectedDate(dateString);

  const { post: createAttendance, reset: resetCreateAttendancePost } = usePost(
    "/api/attendance",
    {
      setLoading: setAttendanceCreateLoading,
      setError: setAttendanceCreateError,
      setData: setAttendanceCreatedRes,
    }
  );

  useEffect(() => {
    if (attendanceCreatedRes?.message && attendanceCreatedRes?.status)
      message?.[attendanceCreatedRes.status](attendanceCreatedRes.message);
    if (attendanceCreateError?.message)
      message.error(attendanceCreateError.message);
    if (attendanceCreatedRes?.message === "created") {
      fetchMark(); // Refresh the marks after creating attendance
    }
    resetCreateAttendancePost();
  }, [attendanceCreatedRes, attendanceCreateError]);

  const columns = useMemo(
    () => generateColumns(attendanceData),
    [attendanceData]
  );

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const [form] = Form.useForm();

  // Filter data based on selected grade and section
  const filteredData = useMemo(() => {
    return attendanceData.filter((item) => {
      return (
        (!selectedGrade || item.grade === selectedGrade) &&
        (!selectedSection || item.section === selectedSection)
      );
    });
  }, [selectedGrade, selectedSection, attendanceData]);

  const handleCreate = () => {
    const selectedStudents = form.getFieldValue("selectedStudents") || [];

    if (!selectedDate || selectedStudents.length === 0) {
      message.error("Please select a date and at least one student.");
      return;
    }

    const dayIndex = parseInt(selectedDate.split("-")[2], 10) - 1;

    selectedStudents.forEach((studentId) => {
      const student = attendanceData.find((item) => item.id === studentId);
      if (student) {
        student.attendance[dayIndex] = "Absent";
      }
    });

    message.success("Attendance updated successfully!");
    handleCancel(); // Call handleCancel after attendance update
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields(); // Reset form fields
  };

  return (
    <div id="Attendance">
      <h2>Attendance Page</h2>
      <Card title="Attendance" bordered={false} style={{ margin: "20px" }}>
        <div className="attendance-page">
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Select
                defaultValue={selectedGrade}
                onChange={handleGradeChange}
                style={{ width: 200 }}
              >
                {grade.map((gra, index) => (
                  <Option key={index} value={gra.val}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Section"
                defaultValue={selectedSection}
                onChange={handleSectionChange}
                style={{ width: 200 }}
              >
                {section.map((sec, index) => (
                  <Option key={index} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <DatePicker onChange={handleDateChange} />
            </Col>
            <Col flex="auto">
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAddModalVisible(true)}
                >
                  Add Attendance
                </Button>
              </div>
            </Col>
          </Row>
          <DynamicTable
            data={filteredData}
            customColumns={columns}
            scroll={{ x: 3800 }}
          />
          <Modal
            title="Mark Attendance"
            open={isAddModalVisible}
            onOk={handleCreate}
            onCancel={() => setIsAddModalVisible(false)}
          >
            <AttendanceForm
              form={form}
              grade={selectedGrade}
              section={selectedSection}
              attendanceData={attendanceData} // Pass attendanceData as prop
              reset={isAddModalVisible}
            />
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default Attendance;
