import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  HomeOutlined,
  FormOutlined,
  TableOutlined,
  ScheduleOutlined,
  ProjectOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  PoweroffOutlined,
  CreditCardOutlined,
  TeamOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { FaUsers, FaUserCheck, FaRegIdCard } from "react-icons/fa";
import { AiFillFileText } from "react-icons/ai";
import { TfiAnnouncement } from "react-icons/tfi";
import { GrCertificate } from "react-icons/gr";
import { useCollapsed } from "../../context/CollapsedContext";

const { Sider } = Layout;

export default function AdminSiderComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, setCollapsed } = useCollapsed();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1000;
      setIsSmallScreen(smallScreen);
      setCollapsed(smallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (e) => {
    const { key } = e;
    navigate(key);
  };

  const menuItems = [
    {
      key: "/app/admin/dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "registration",
      icon: <FaUsers />,
      label: "Registration",
      children: [
        { key: "/app/admin/staff", label: "Staff" },
        { key: "/app/admin/students", label: "Student" },
      ],
    },
    {
      key: "/app/admin/profile",
      icon: <HomeOutlined />,
      label: "School Profile",
    },
    {
      key: "/app/admin/history",
      icon: <AiFillFileText />,
      label: "History",
    },
    {
      key: "/app/admin/subject",
      icon: <BookOutlined />,
      label: "Subject",
    },
    {
      key: "/app/admin/assessmentList",
      icon: <FormOutlined />,
      label: "Assessment Lists",
    },
    {
      key: "/app/admin/Assessments",
      icon: <TableOutlined />,
      label: "Assessments",
    },
    {
      key: "/app/admin/attendance",
      icon: <FaUserCheck />,
      label: "Attendance",
    },
    {
      key: "/app/admin/time-table",
      icon: <ScheduleOutlined />,
      label: "Time Table",
    },
    {
      key: "/app/admin/terms",
      icon: <ProjectOutlined />,
      label: "Academic Terms",
    },
    {
      key: "/app/admin/gradeDivisions",
      icon: <ApartmentOutlined />,
      label: "Grade Divisions",
    },
    {
      key: "/app/admin/organize",
      icon: <TeamOutlined />,
      label: "Organize Students",
    },
    {
      key: "/app/admin/teacher-task",
      icon: <SolutionOutlined />,
      label: "Teacher's Task",
    },
    {
      key: "/app/admin/activation",
      icon: <PoweroffOutlined />,
      label: "Activation",
    },
    {
      key: "/app/admin/payment",
      icon: <CreditCardOutlined />,
      label: "Payment",
    },
    {
      key: "/app/admin/announcement",
      icon: <TfiAnnouncement />,
      label: "Announcement",
    },
    {
      key: "id",
      icon: <FaRegIdCard />,
      label: "Id",
      children: [
        { key: "/app/admin/student-id", label: "Student ID" },
        { key: "/app/admin/staff-id", label: "Staff ID" },
      ],
    },
    {
      key: "/app/admin/certificate",
      icon: <GrCertificate />,
      label: "Certificate",
    },
  ];

  return (
    <Layout className="custom-sider">
      <Sider
        style={{ backgroundColor: "white" }}
        collapsible={isSmallScreen}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        collapsedWidth={0}
        breakpoint="md"
        trigger={null}
      >
        <Menu
          style={{ backgroundColor: "white", marginTop: 30 }}
          mode="inline"
          selectedKeys={[location.pathname]} // Highlight current path
          onClick={handleMenuClick}
          items={menuItems}
        />
      </Sider>
    </Layout>
  );
}
