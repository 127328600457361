import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { DashboardOutlined, TableOutlined } from "@ant-design/icons";
import { FaUserCheck, FaEnvelope } from "react-icons/fa";
import { AiFillFileText, AiOutlineHistory } from "react-icons/ai";
import { TfiAnnouncement } from "react-icons/tfi";
import { useCollapsed } from "../../context/CollapsedContext";

const { Sider } = Layout;

export const teacherMenuItems = [
  {
    key: "dashboard",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    href: "/app/teacher/dashboard",
  },
  {
    key: "generateQuestions",
    icon: <AiFillFileText />,
    label: "Generate Questions",
    href: "/app/teacher/question",
  },
  {
    key: "history",
    icon: <AiOutlineHistory />,
    label: "History",
    href: "/app/teacher/history",
  },
  {
    key: "assessments",
    icon: <TableOutlined />,
    label: "Assessments",
    href: "/app/teacher/assessments",
  },
  {
    key: "attendance",
    icon: <FaUserCheck />,
    label: "Attendance",
    href: "/app/teacher/attendance",
  },
  {
    key: "announcement",
    icon: <TfiAnnouncement />,
    label: "Announcement",
    href: "/app/teacher/announcement",
  },
  {
    key: "message",
    icon: <FaEnvelope />,
    label: "Message",
    href: "/app/teacher/message",
  },
];

export default function TeacherSiderComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, setCollapsed } = useCollapsed();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1000;
      setIsSmallScreen(smallScreen);
      setCollapsed(smallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  const menuItems = teacherMenuItems.map((item) => ({
    key: item.href,
    icon: item.icon,
    label: item.label,
  }));

  return (
    <Layout className="custom-sider">
      <Sider
        style={{ backgroundColor: "white" }}
        collapsible={isSmallScreen}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        collapsedWidth={0}
        breakpoint="md"
        trigger={null}
      >
        <Menu
          style={{ backgroundColor: "white", marginTop: 30 }}
          mode="inline"
          selectedKeys={[location.pathname]} // Highlight current path
          onClick={handleMenuClick}
          items={menuItems}
        />
      </Sider>
    </Layout>
  );
}
