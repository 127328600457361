import React, { useState, useMemo } from "react";
import { Table, Input, Row, Col, Select, Button } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const TermsTable = ({ terms = [], loading, onEdit, setModalClosed }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("name");

  // Generate dynamic columns, similar to ServiceTable
  const columns = useMemo(() => {
    const baseColumns = [
      { title: "Term ID", dataIndex: "id", key: "id" },
      { title: "Term Name", dataIndex: "name", key: "name" },
      { title: "Code", dataIndex: "code", key: "code" },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        render: (startDate) => new Date(startDate).toLocaleDateString(),
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        render: (endDate) => new Date(endDate).toLocaleDateString(),
      },
      { title: "Order", dataIndex: "order", key: "order" },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <span
            style={{ fontWeight: status === "Current" ? "bold" : "normal" }}
          >
            {status}
          </span>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div className="tableSubCols details">
              Set By: {record["createdBy"]}
            </div>
            <div className="tableSubCols details">
              Created: {new Date(record["createdAt"]).toLocaleDateString()}
            </div>
            <div className="tableSubCols details">
              Updated: {new Date(record["updatedAt"]).toLocaleDateString()}
            </div>
          </>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Button type="primary" onClick={() => onEdit(record)}>
            <EditOutlined /> Edit
          </Button>
        ),
      },
    ];

    return baseColumns;
  }, [onEdit]);

  // Handle filtering the terms data
  const filteredData = useMemo(() => {
    if (!terms) return [];
    return terms.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [terms, searchText, selectedColumn]);

  // Handle search input change
  const handleSearchChange = (e) => setSearchText(e.target.value);

  // Handle column selection change
  const handleColumnChange = (value) => setSelectedColumn(value);

  // Column options for filtering
  const columnOptions = Object.keys(terms?.[0] || {}).map((col) => ({
    key: col,
    value: col,
    label: col.replace(/_/g, " ").toUpperCase(),
  }));

  return (
    <div id="TermsTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
            aria-label="Search"
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
            aria-label="Select Column"
          />
        </Col>
        <Col flex="auto">
          {setModalClosed && (
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalClosed(false)}
              >
                Add Term
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        loading={loading}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default TermsTable;
