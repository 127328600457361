import React, { useState, useMemo } from "react";
import { Button, Input, Row, Col, Select, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";

const excludedCols = [
  "id",
  "TASK ID",
  "Teacher NAME",
  "GRADE",
  "SECTION",
  "SUBJECT NAME",
  "SUBJECT TYPE",
  "ASSIGNED BY",
];

const generateColumns = (data, deleteTask) => {
  console.log("data================>", data);

  if (!data || data.length === 0) return [];

  const commonColumns = [
    {
      width: 50,
      title: "ID",
      key: "id",
      render: (text, record) => (
        <>
          <div className="tableSubCols ">{record["id"]}</div>
        </>
      ),
    },
    {
      width: 200,
      title: "TASK Info",
      key: "info",
      render: (text, record) => (
        <>
          <div className="tableSubCols heading">
            TeacherName: {record["Teacher NAME"]}
          </div>
          <div className="tableSubCols details">
            Task ID: {record["TASK ID"]}
          </div>
        </>
      ),
    },
    {
      title: "Grade",
      key: "grade",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">Grade: {record["GRADE"]}</div>
          <div className="tableSubCols details">
            Section: {record["SECTION"]}
          </div>
        </>
      ),
    },
    {
      title: "Subject",
      key: "subject",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Name: {record["SUBJECT NAME"]}
          </div>
          <div className="tableSubCols details">
            Type: {record["SUBJECT TYPE"]}
          </div>
        </>
      ),
    },
    {
      width: 150,
      title: "Assigned By",
      key: "assigned By",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Assigned by: {record["ASSIGNED BY"]}
          </div>
        </>
      ),
    },
  ];

  const sampleItem = data[0];
  const keys = Object.keys(sampleItem).filter(
    (key) => !excludedCols.includes(key)
  );

  const dynamicColumns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    key,
    dataIndex: key,
  }));

  dynamicColumns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Popconfirm
          onConfirm={() => {
            deleteTask(record);
          }}
          title="Remove Task?"
          okText="Yes"
          cancelText="No"
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </span>
    ),
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Button onClick={() => handleRemoveTask(record.id)} danger>
    //       Remove
    //     </Button>
    //   ),
    //   },
  });

  return [...commonColumns, ...dynamicColumns];
};
// const handleRemoveTask = (id) => {
//   setTask(task.filter((task) => task.id !== id));
// };
const TaskTable = ({ taskData = [], taskLoading, deleteTask }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("Task Name");

  const customColumns = useMemo(
    () => generateColumns(taskData, deleteTask),
    [taskData]
  );

  const filteredData = useMemo(() => {
    if (!taskData) return [];
    return taskData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [taskData, searchText, selectedColumn]);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const columnOptions = excludedCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="TaskTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
            aria-label="Search"
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
            aria-label="Select Column"
          />
        </Col>
        <Col flex="auto">
          {/* <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalClosed(false)}
            >
              Add
            </Button>
          </div> */}
        </Col>
      </Row>
      <DynamicTable
        data={filteredData}
        loading={taskLoading}
        customColumns={customColumns}
        scroll={{ x: 900 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default TaskTable;
