import React from "react";
import { Card, Button, Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import "./PricingPage.css";
import "../../web/landing-page/LandingPage.css";

const basicFeatures = [
  "Student Data Management",
  "Teacher Management",
  "Payment Processing",
  "Staff Management",
  "Class Scheduling",
  "Attendance Tracking",
  "ID Card Generation",
  "Parental Communication",
  "Basic Reporting",
  "Student Portal Access",
];

const standardAdditionalFeatures = [
  "SMS Notifications",
  "Digital ID Cards",
  "Advanced Attendance Tracking",
  "Digital Bell System",
  "Parent-Teacher Communication",
];

const premiumAdditionalFeatures = [
  "Ready-to-Print Question Generator, including Tests, Worksheets, and More",
];

const pricingData = [
  {
    title: "Basic",
    name: "basic",
    price: "ETB 3,000",
    description: "PER MONTH",
    features: basicFeatures,
  },
  {
    title: "Standard",
    name: "standard",
    price: "ETB 4,000",
    description: "PER MONTH",
    features: [...basicFeatures, ...standardAdditionalFeatures],
  },
  {
    title: "Premium",
    name: "premium",
    price: "ETB 5,000",
    description: "PER MONTH",
    features: [
      ...basicFeatures,
      ...standardAdditionalFeatures,
      ...premiumAdditionalFeatures,
    ],
  },
];

const PricingPage = () => {
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    // setUserAuthStatus({ ...userAuthStatus, authenticated: false, role: null });
    // localStorage.setItem("accessToken", undefined);
    navigate("/auth/school");
  };
  return (
    <div id="LandingPage">
      <div id="PaymentPage">
        <div className="main-nav" id="main-nav" style={{ zIndex: 11 }}>
          <div className="container main-nav-container">
            <div
              className="logo-container"
              onClick={() => {
                console.log("clicked");
                navigate("/");
              }}
            >
              <img src="../../images/logo.svg" alt="Logo" />
              <a className="logo">
                <span className="text-secondary">TOFI</span>
              </a>
            </div>

            <div className="menu-btn-container">
              <a href="#" className="btn-menu">
                <i className="fa-solid fa-bars fa-2x"></i>
              </a>
            </div>

            <div className="main-menu"></div>
            <div className="auth-container">
              <ul className="auth-ul">
                <li>
                  <button className="btn" onClick={handleLoginNavigate}>
                    Login
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "50px 30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="payment-showcase"
        >
          <div className="row-pricing">
            <Row gutter={24}>
              {pricingData.map((plan, index) => (
                <Col
                  xs={24} // Full width on extra small screens
                  sm={24} // Half width on small screens
                  md={8} // One-third width on medium screens
                  key={plan.title}
                  className={index === 1 ? "featured-plan" : "standard-plan"}
                >
                  <Card
                    title={plan.title}
                    className="cards-content"
                    hoverable
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="price-description">
                        <h1>{plan.price}</h1>
                        <p>{plan.description}</p>
                      </div>
                      <ul>
                        {plan.features.map((feature, idx) => (
                          <li
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: feature }}
                          ></li>
                        ))}
                      </ul>
                    </div>
                    <Button
                      type="primary"
                      className="signup-button"
                      onClick={() => {
                        localStorage.setItem("subscriptionType", plan.name);
                        navigate("/school-registration");
                      }}
                      disabled={plan.name !== "basic"}
                    >
                      <p style={{ color: "white", margin: 0 }}>
                        Sign Up{" "}
                        {plan.name !== "basic" ? (
                          <p
                            style={{ color: "white", margin: 0, fontSize: 12 }}
                          >
                            will be available soon
                          </p>
                        ) : (
                          ""
                        )}
                      </p>
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
