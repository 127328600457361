import React, { useState, useEffect } from "react";
import { Form, Select, Input, Button, List } from "antd";

const { Option } = Select;

const studentsData = {
  "1-B": [
    { id: "St-16-O-A-H#Bc268", name: "Abdellah Abdulatif Jemal" },
    { id: "St-21-1-B-H#Bc270", name: "Sara Ahmed" },
  ],
  "2-B": [{ id: "St-16-O-A-H#Bc264", name: "Ahafiz Ali Yusuf" }],
};

const MessageForm = ({ grade, section, onSendMessage }) => {
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const key = `${grade}-${section}`;
    setStudentOptions(studentsData[key] || []);
  }, [grade, section]);

  const handleSend = () => {
    if (selectedStudent || message) {
      const messageData = {
        studentName: selectedStudent ? selectedStudent.name : "All Students",
        message,
      };
      console.log(messageData);
      onSendMessage(messageData);
      setSelectedStudent(null);
      setMessage("");
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Search Student">
        <Select
          showSearch
          placeholder="Select a student"
          value={selectedStudent ? selectedStudent.id : undefined}
          onChange={(value) => {
            const student = studentOptions.find((item) => item.id === value);
            setSelectedStudent(student);
          }}
          optionFilterProp="children"
        >
          {studentOptions.map((student) => (
            <Option key={student.id} value={student.id}>
              {student.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Message Description">
        <Input.TextArea
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          onClick={handleSend}
          disabled={!message || !selectedStudent}
        >
          Send
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          onClick={handleSend}
          disabled={!message || selectedStudent}
        >
          Send for All
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MessageForm;
