import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  Checkbox,
  Radio,
  message,
} from "antd";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import "./TeacherQuestionGenerator.css"; // Import the CSS file
import useFetchTerms from "../../../hooks/useFetchTerms";
import jsPDF from "jspdf"; // Import jsPDF for PDF generation

const { Option } = Select;

const TeacherQuestionGenerator = () => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(1); // Step to control form flow
  const [formValues, setFormValues] = useState({});
  const [assessmentType, setAssessmentType] = useState("");
  const [customType, setCustomType] = useState("");
  const [generatedExam, setGeneratedExam] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [chapterModalVisible, setChapterModalVisible] = useState(false);
  const [chapterModalData, setChapterModalData] = useState({
    chapter: "",
    portion: "",
    questionCount: "",
    assessmentType: "",
  });
  const [customInstance, setCustomInstance] = useState([]);
  const [availableChapters, setAvailableChapters] = useState([]);
  const [availablePortions, setAvailablePortions] = useState([]);
  const [portionQuestions, setPortionQuestions] = useState({}); // Store questions for each portion
  const [questionFormats, setQuestionFormats] = useState({}); // Store formats for each portion
  const [currentChapter, setCurrentChapter] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState("Grade 2");
  // const [selectedSubject, setSelectedSubject] = useState("English");

  // For suggestion content in additional detail
  const [additionalDetailSuggestions] = useState([
    "Make questions harder",
    "Focus on important topics",
    "Include more practice questions",
  ]);
  //
  const [totalQuestions, setTotalQuestions] = useState(0); // Total number of questions entered
  const [questionsLeft, setQuestionsLeft] = useState(0); // Remaining number of questions
  const [portionData, setPortionData] = useState({});
  const [gradeList, setGradeList] = useState([]); // Initialize as an empty array
  const [subjectList, setSubjectList] = useState([]); // Initialize as an empty array
  const [termList, setTermList] = useState([]); // Initialize as an empty array
  const [assessmentList, setAssessmentList] = useState([]); // Initialize as an empty array

  // Hook for submitting the generated exam to backend
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createdRes, setCreatedRes] = useState(null);
  //
  const [questionRes, setQuestionRes] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [questionError, setQuestionError] = useState(null);
  //
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updatedRes, setUpdatedRes] = useState(null);
  // Refactored useFetch for teacher Task
  const [tasks, setTasks] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskFetchError, setTaskFetchError] = useState(null);
  // Fetch related states
  const [assessments, setAssessments] = useState([]);
  const [assLoading, setAssLoading] = useState(false);
  const [fetchAssError, setFetchAssError] = useState(null);
  // Fetch Assessments
  const { get: fetchAss } = useFetch("/api/assessment", {
    setLoading: setAssLoading,
    setError: setFetchAssError,
    setData: setAssessments,
  });
  const { get: fetchTask } = useFetch("/api/teacherTask", {
    setLoading: setTaskLoading,
    setError: setTaskFetchError,
    setData: setTasks,
  });

  const { get: getQuestion } = useFetch("api/questions", {
    setLoading: setQuestionLoading,
    setError: setQuestionError,
    setData: setQuestionRes,
  });

  const { post: createQuestion, reset: resetPost } = usePost("/api/questions", {
    setLoading: setCreateLoading,
    setError: setCreateError,
    setData: setCreatedRes,
  });

  const { patch: updateQuestion, reset: resetUpdate } = usePatch(
    `/api/questions/${generatedExam?.id}`,
    {
      setLoading: setUpdateLoading,
      setError: setUpdateError,
      setData: setUpdatedRes,
    }
  );
  const { terms, fetchTermsLoading, fetchTermsError, fetchTerms } =
    useFetchTerms();
  useEffect(() => {
    const grade = form.getFieldValue("grade");
    setSelectedGrade(grade);
    fetchAss({ grade: selectedGrade });
    console.log("assessments------", assessments, selectedGrade);
  }, [selectedGrade]);
  useEffect(() => {
    fetchTerms();
  }, []);
  useEffect(() => {
    getQuestion();
  }, []);
  useEffect(() => {
    fetchTask();
  }, []);
  useEffect(() => {
    if (tasks?.length) {
      // Check if tasks is defined and has elements
      const uniqueGrades = [...new Set(tasks.map((task) => task.GRADE))];
      const uniqueSubject = [
        ...new Set(tasks.map((task) => task["SUBJECT NAME"])),
      ];
      const uniqueTerm = [...new Set(terms.map((term) => term.name))];
      const uniqueAss = [
        ...new Set(
          assessments
            .filter((assessment) => assessment.grade == selectedGrade)
            .map((assessment) => assessment.name)
        ),
      ];

      setGradeList(uniqueGrades);
      setSubjectList(uniqueSubject);
      setTermList(uniqueTerm);
      setAssessmentList(uniqueAss);
    }
  }, [tasks, assessments, selectedGrade]);
  useEffect(() => {
    setGeneratedExam(questionRes?.exams?.[questionRes?.exams?.length - 1]);
    console.log("============terms", subjectList, termList, gradeList);
    console.log("============---------assessmentList", assessmentList);
  }, [questionRes]);

  useEffect(() => {
    console.log(createdRes);
    if (createdRes?.message) message[createdRes?.status](createdRes?.message);

    if (createdRes?.status === "success") {
      setIsModalVisible(false);
      form.resetFields();
    }
    getQuestion(); // Refetch payments after a successful post
    resetPost();
  }, [createdRes]);

  useEffect(() => {
    console.log(createError);
    if (createError?.message)
      message[createError?.status || "error"](createError?.message);
    resetPost();
  }, [createError]);
  // Options
  const assessmentTypes = [
    "Final Exam",
    "Mid Exam",
    "Test",
    "Worksheet",
    "Exercise",
    "Quiz",
  ];

  const chaptersAndPortions = {
    "Grade 1": {
      Math: {
        chapters: ["Chapter 1", "Chapter 2"],
        portions: {
          "Chapter 1": ["Part A", "Part B"],
          "Chapter 2": ["Part A", "Part B", "Part C"],
        },
      },
      Science: {
        chapters: ["Chapter 1", "Chapter 2", "Chapter 3"],
        portions: {
          "Chapter 1": ["Part A", "Part B"],
          "Chapter 2": ["Part A", "Part B"],
          "Chapter 3": ["Part A", "Part B", "Part C"],
        },
      },
      English: {
        chapters: ["Chapter 1", "Chapter 2"],
        portions: {
          "Chapter 1": ["Part A", "Part B"],
          "Chapter 2": ["Part A", "Part B", "Part C"],
        },
      },
    },
    "Grade 2": {
      Math: {
        chapters: ["Chapter 1", "Chapter 2", "Chapter 3"],
        portions: {
          "Chapter 1": ["Part A", "Part B"],
          "Chapter 2": ["Part A", "Part B"],
          "Chapter 3": ["Part A", "Part B", "Part C"],
        },
      },
      English: {
        chapters: ["Chapter 1", "Chapter 2"],
        portions: {
          "Chapter 1": ["Part A", "Part B"],
          "Chapter 2": ["Part A", "Part B", "Part C"],
        },
      },
    },
  };

  const handleGradeOrSubjectChange = () => {
    const grade = form.getFieldValue("grade");
    const subject = form.getFieldValue("subject");

    if (grade && subject && chaptersAndPortions[grade]?.[subject]) {
      setAvailableChapters(chaptersAndPortions[grade][subject].chapters || []);
    } else {
      setAvailableChapters([]);
    }
  };

  useEffect(() => {
    handleGradeOrSubjectChange();
  }, [form.getFieldValue("grade"), form.getFieldValue("subject")]);

  useEffect(() => {
    const grade = form.getFieldValue("grade");
    const subject = form.getFieldValue("subject");
    const chapter = chapterModalData.chapter; // Ensure the chapter is correctly updated
    setSelectedGrade(grade);
    if (grade && subject && chapter) {
      const portionsForChapter =
        chaptersAndPortions[grade]?.[subject]?.portions[chapter];

      if (portionsForChapter) {
        setAvailablePortions(portionsForChapter);
        console.log("Portions for the selected chapter: ", portionsForChapter);
      } else {
        console.log("No portions found for the selected chapter");
        setAvailablePortions([]);
      }
    } else {
      console.log("Incomplete data to fetch portions");
      setAvailablePortions([]);
    }
  }, [
    chapterModalData.chapter, // This will trigger the effect when the chapter changes
    form.getFieldValue("grade"), // This will trigger the effect when the grade changes
    form.getFieldValue("subject"), // This will trigger the effect when the subject changes
  ]);

  // Make sure to handle the chapter selection correctly
  const handleChapterSelect = (checkedValues) => {
    const selectedChapter = checkedValues[checkedValues.length - 1];
    setSelectedChapters(checkedValues);

    if (selectedChapter) {
      if (portionData[selectedChapter]) {
        setChapterModalData({
          ...chapterModalData,
          ...portionData[selectedChapter],
          chapter: selectedChapter,
        });
      } else {
        setChapterModalData({
          chapter: selectedChapter,
          portion: "",
          questionCount: "",
          assessmentType: "",
        });
      }
      setChapterModalVisible(true);
    }
  };

  const handleGenerate = async () => {
    console.log("------------------generatedExam", generatedExam);

    // Build questionData based on selected chapters and portions in portionData
    const questionData = selectedChapters.map((chapter) => ({
      chapterName: chapter,
      chapterNumber: chapter,
      portions: Object.keys(portionData[chapter] || {}).map((portionName) => ({
        portionName,
        totalPortionQuestions:
          portionData[chapter][portionName].totalPortionQuestions || 0,
        questionFormat: portionData[chapter][portionName].questionFormat || "",
      })),
    }));

    // Adjust customType logic: Show customInstance only if assessmentType is not "finalExam" or "midExam"
    const examType =
      assessmentType !== "midExam" &&
      assessmentType !== "finalExam" &&
      customInstance
        ? `${assessmentType} ${customInstance}`
        : assessmentType;

    // Collect all form values, including questionData and questions template
    const submittedData = {
      ...form.getFieldsValue(),
      assessmentType: examType,
      questionData,
      questions: {
        questionsDetail: [
          // True/False Questions with Header
          {
            id: 1,
            question: "The earth revolves around the sun.",
            type: "True/False",
          },
          {
            id: 2,
            question: "Water freezes at 0 degrees Celsius.",
            type: "True/False",
          },
          { id: 3, question: "A square has three sides.", type: "True/False" },

          // Matching Questions with Header
          {
            id: 4,
            question: "Match the animal to its habitat.",
            type: "Matching",
            columnA: ["1) Lion", "2) Penguin", "3) Camel"],
            columnB: ["A) Savanna", "B) Antarctica", "C) Desert"],
          },

          // Multiple-Choice Questions with Header
          {
            id: 7,
            question: "What is 10 + 15?",
            type: "Multiple Choice",
            choices: ["A) 20", "B) 25", "C) 30", "D) 35"],
          },
          {
            id: 8,
            question: "Which planet is known as the Red Planet?",
            type: "Multiple Choice",
            choices: ["A) Venus", "B) Mars", "C) Jupiter", "D) Saturn"],
          },
          {
            id: 9,
            question: "What is the capital of France?",
            type: "Multiple Choice",
            choices: ["A) Berlin", "B) Madrid", "C) Paris", "D) Rome"],
          },

          // Fill-in-the-Blank Questions with Header
          {
            id: 10,
            question: "The chemical symbol for water is ____.",
            type: "Fill in the Blank",
          },
          {
            id: 11,
            question: "The tallest mountain in the world is ____.",
            type: "Fill in the Blank",
          },
          {
            id: 12,
            question: "The Great Wall of China is located in ____.",
            type: "Fill in the Blank",
          },

          // Short Answer Questions with Header
          {
            id: 13,
            question: "Explain why the sky appears blue.",
            type: "Short Answer",
          },
          { id: 14, question: "What is photosynthesis?", type: "Short Answer" },
          {
            id: 15,
            question: "Describe the process of evaporation.",
            type: "Short Answer",
          },
        ],
        answers: {
          1: "True",
          2: "True",
          3: "False",
          4: "Lion - Savanna, Penguin - Antarctica, Camel - Desert",
          5: "Red - Apple, Yellow - Sun, Blue - Sky",
          6: "Newton - Gravity, Einstein - Relativity, Curie - Radioactivity",
          7: "B) 25",
          8: "B) Mars",
          9: "C) Paris",
          10: "H2O",
          11: "Mount Everest",
          12: "China",
          13: "The sky appears blue due to the scattering of light by the atmosphere.",
          14: "Photosynthesis is the process by which green plants make their food using sunlight.",
          15: "Evaporation is the process where liquid water turns into vapor.",
        },
      },
    };

    try {
      console.log("Submitting data:", submittedData);
      await createQuestion(submittedData);

      setStep(3);
      resetPost();
    } catch (error) {
      console.error("Failed to submit the exam:", error);
      message.error("Failed to submit the exam.");
    }
  };
  const feedbackOptions = [
    "The questions were too easy.",
    "The questions were too difficult.",
    "The questions did not cover the required topics.",
    "The exam format was unclear.",
  ];
  const handleAssessmentTypeChange = (e) => {
    const selectedType = e.target.value;
    setAssessmentType(selectedType);

    // Clear instance number when selecting "Exam"
    if (selectedType === "Exam") {
      setCustomInstance("");
    }
  };
  const handleModalOk = () => {
    setPortionData((prev) => ({
      ...prev,
      [chapterModalData.chapter]: {
        ...prev[chapterModalData.chapter],
        [chapterModalData.portion]: {
          totalPortionQuestions: chapterModalData.questionCount,
          questionFormat: chapterModalData.assessmentType,
        },
      },
    }));
    setChapterModalVisible(false);
  };

  const handleModalCancel = () => {
    setChapterModalVisible(false);
  };
  const handlePrint = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;

    let yOffset = 20; // Start position on the page

    // Exam Header - Listed in two rows
    const headerRow1 = `Grade: ${generatedExam.grade}                 | Subject: ${generatedExam.subject}`;
    const headerRow2 = `Semester: ${generatedExam.semester}           | Assessment Type: ${generatedExam.assessmentType}`;
    const headerRow3 = `Allowed Hour: ${
      generatedExam.allowedHour
    }                                                               | Date: ${generatedExam.updatedAt.slice(
      0,
      10
    )}`;

    // Set header font and content
    doc.setFontSize(14);
    doc.text("Exam Details", 10, yOffset);
    yOffset += 10;

    doc.setFontSize(12);
    doc.text(headerRow1, 10, yOffset);
    yOffset += 10;
    doc.text(headerRow2, 10, yOffset);
    yOffset += 10;
    doc.text(headerRow3, 10, yOffset);
    yOffset += 20; // Add some extra space before the border

    // Draw a border line between header and questions
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.line(10, yOffset, 200, yOffset);
    yOffset += 10; // Add space after the line

    // Add Questions
    parseQuestions(generatedExam.questions).forEach((q, idx) => {
      const questionText = `Q${idx + 1}: ${q.question}`;
      const answerText = `Answer: ${q.answer}`;

      // Break text into lines if it exceeds the page width
      const questionLines = doc.splitTextToSize(questionText, 180);
      const answerLines = doc.splitTextToSize(answerText, 180);

      if (yOffset + questionLines.length * 10 > pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }

      doc.setFontSize(12);
      doc.text(questionLines, 15, yOffset);
      yOffset += questionLines.length * 10 + 10;

      if (yOffset + answerLines.length * 10 > pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }

      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(answerLines, 15, yOffset);
      yOffset += answerLines.length * 10 + 1;

      if (yOffset >= pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }
    });

    doc.save(`${generatedExam.grade}-${generatedExam.subject}-exam.pdf`);
  };
  const handleSubmit = (values) => {
    setFormValues(values);
    setStep(2);
  };
  const handleSubmitFeedback = () => {
    updateQuestion({ feedback });
    setIsModalVisible(false);
  };

  const handleRegenerate = () => {
    setIsModalVisible(true);
  };

  //
  // Track total number of questions from form
  const handleTotalQuestionsChange = (value) => {
    setTotalQuestions(value);
    setQuestionsLeft(value); // Initially, all questions are left
  };
  // Calculate and track the number of remaining questions after user input
  const handlePortionQuestionChange = (chapter, portion, value) => {
    setPortionData((prev) => {
      const updatedPortionData = {
        ...prev,
        [chapter]: {
          ...prev[chapter],
          [portion]: {
            ...prev[chapter]?.[portion],
            totalPortionQuestions: value,
          },
        },
      };

      // Calculate cumulative question count across all chapters
      const totalUsedQuestions = Object.values(updatedPortionData).reduce(
        (chapterAcc, chapterPortions) =>
          chapterAcc +
          Object.values(chapterPortions).reduce(
            (portionAcc, { totalPortionQuestions }) =>
              portionAcc + (totalPortionQuestions || 0),
            0
          ),
        0
      );

      setQuestionsLeft(totalQuestions - totalUsedQuestions);

      // Show error if total used questions exceed totalQuestions
      if (totalUsedQuestions > totalQuestions) {
        message.error("You have exceeded the total available questions!");
      }

      return updatedPortionData;
    });
  };

  const handleQuestionFormatChange = (chapter, portion, value) => {
    setQuestionFormats((prev) => ({
      ...prev,
      [portion]: value, // Update the format for the specific portion
    }));

    setPortionData((prev) => ({
      ...prev,
      [chapter]: {
        ...prev[chapter],
        [portion]: {
          ...prev[chapter]?.[portion],
          questionFormat: value,
        },
      },
    }));
  };
  const parseQuestions = (questions) => {
    try {
      const parsed =
        typeof questions === "string" ? JSON.parse(questions) : questions;
      return Array.isArray(parsed) ? parsed : []; // Ensure an array is always returned
    } catch (error) {
      console.error("Failed to parse questions:", error);
      return []; // Return an empty array in case of failure
    }
  };
  return (
    <div id="teacher-question-generator" className="container">
      <h2 className="main-title">Generate Exam</h2>

      {step === 1 && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="question-form"
          // initialValues={{
          //   grade: "Grade 1",
          //   subject: "Math",
          //   term: "Semester 1",
          //   allowedHour: "2 Hours",
          //   assessmentType: "Test",
          //   totalQuestions: 50,
          // }}
        >
          {/* Grade */}
          <Form.Item
            label="Grade"
            name="grade"
            rules={[{ required: true, message: "Please select the grade" }]}
          >
            <Select
              placeholder="Select Grade"
              onChange={handleGradeOrSubjectChange}
              value={selectedGrade}
            >
              {gradeList?.map((grade) => (
                <Option key={grade} value={grade}>
                  {grade}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Subject */}
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Please select the subject" }]}
          >
            <Select
              placeholder="Select Subject"
              onChange={handleGradeOrSubjectChange}
              // value={selectedGrade}
            >
              {subjectList.map((subject) => (
                <Option key={subject} value={subject}>
                  {subject}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* amountOfQuestions */}
          <Form.Item
            label="Total Questions"
            name="totalQuestions"
            rules={[{ required: true, message: "Please enter amount" }]}
          >
            <Input
              placeholder="Total amount of questions?"
              onChange={(e) =>
                handleTotalQuestionsChange(Number(e.target.value))
              }
            />
          </Form.Item>

          {/* Chapter Selection */}
          <Form.Item label="Chapters">
            <Checkbox.Group
              options={availableChapters}
              onChange={handleChapterSelect}
            />
          </Form.Item>

          {/* Semester */}
          <Form.Item
            label="Term"
            name="terms"
            rules={[{ required: true, message: "Please select the Term" }]}
          >
            <Select placeholder="Select Semester">
              {termList?.map((term) => (
                <Option key={term} value={term}>
                  {term}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Allowed Hour */}
          <Form.Item
            label="Allowed Hour"
            name="allowedHour"
            rules={[
              { required: true, message: "Please enter the allowed hour" },
            ]}
          >
            <Input placeholder="Enter allowed hour (e.g., 2 hours)" />
          </Form.Item>

          {/* Assessment Type */}
          <Form.Item
            label="Assessment Type"
            name="assessmentType"
            rules={[
              { required: true, message: "Please select the assessment type" },
            ]}
          >
            <Radio.Group
              onChange={handleAssessmentTypeChange}
              value={assessmentType}
            >
              {assessmentList?.map((assessment) => (
                <Radio key={assessment} value={assessment}>
                  {assessment}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* Additional Detail */}
          <Form.Item
            label="Additional Detail"
            name="additionalDetail"
            rules={[
              { required: true, message: "Please enter the additional detail" },
            ]}
          >
            <Select
              placeholder="Enter Additional Detail"
              options={additionalDetailSuggestions.map((suggestion) => ({
                value: suggestion,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={handleGenerate}
              className="generate-btn"
            >
              Generate Exam
            </Button>
          </Form.Item>
        </Form>
      )}

      {/* Display Generated Exam (Step 3) */}
      {step === 3 && generatedExam && (
        <div className="generated-exam">
          <div className="exam-header">
            <h3 className="exam-title">Generated Exam</h3>
            <div className="exam-info">
              <p>
                <strong>Date:</strong> {generatedExam?.updatedAt.slice(0, 10)}
              </p>
              <p>
                <strong>Grade:</strong> {generatedExam.grade}
              </p>
              <p>
                <strong>Subject:</strong> {generatedExam.subject}
              </p>
              <p>
                <strong>Allowed Hour:</strong> {generatedExam.allowedHour}
              </p>
              <p>
                <strong>Term:</strong> {generatedExam?.terms}
              </p>
              <p>
                <strong>Assessment Type:</strong> {generatedExam.assessmentType}
              </p>
            </div>
          </div>

          <div className="exam-content">
            {/* Display Instructions and Questions */}
            <h4>
              True/False: Say 'True' if the statement is correct and 'False' if
              not.
            </h4>
            {generatedExam.questions.questionsDetail
              .filter((q) => q.type === "True/False")
              .map((question) => (
                <div key={question.id} style={{ marginBottom: "16px" }}>
                  <p>
                    <strong>{question.id}:</strong> {question.question}
                  </p>
                </div>
              ))}

            {generatedExam?.questions?.questionsDetail
              ?.filter((q) => q.type === "Matching")
              ?.map((question) => (
                <div key={question.id} style={{ marginBottom: "16px" }}>
                  <h4>{question.question}</h4>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* Column A */}
                    <div style={{ flex: 1, padding: "0 10px" }}>
                      {question.columnA.map((item, index) => (
                        <p key={index}>{item}</p>
                      ))}
                    </div>
                    {/* Column B */}
                    <div style={{ flex: 1, padding: "0 10px" }}>
                      {question.columnB.map((item, index) => (
                        <p key={index}>{item}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

            <h4>Multiple Choice: Choose the correct answer.</h4>
            {generatedExam.questions.questionsDetail
              .filter((q) => q.type === "Multiple Choice")
              .map((question) => (
                <div key={question.id} style={{ marginBottom: "16px" }}>
                  <p>
                    <strong>{question.id}:</strong> {question.question}
                  </p>
                  <p>{question.choices.join(" ")}</p>
                </div>
              ))}

            <h4>Fill in the Blank: Provide the correct word(s).</h4>
            {generatedExam.questions.questionsDetail
              .filter((q) => q.type === "Fill in the Blank")
              .map((question) => (
                <div key={question.id} style={{ marginBottom: "16px" }}>
                  <p>
                    <strong>{question.id}:</strong> {question.question}
                  </p>
                </div>
              ))}

            <h4>Short Answer: Write a brief response.</h4>
            {generatedExam.questions.questionsDetail
              .filter((q) => q.type === "Short Answer")
              .map((question) => (
                <div key={question.id} style={{ marginBottom: "16px" }}>
                  <p>
                    <strong>{question.id}:</strong> {question.question}
                  </p>
                </div>
              ))}
          </div>

          <Button onClick={handleRegenerate} className="regenerate-btn">
            Regenerate
          </Button>
          <Button onClick={handlePrint} className="print-btn">
            Print
          </Button>
        </div>
      )}

      {/* Modal for Chapter Details */}
      <Modal
        title={`Chapter Details (${questionsLeft} questions left)`}
        visible={chapterModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form>
          {/* Loop through available portions */}
          {availablePortions.map((portion, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <Checkbox value={portion}>{portion}</Checkbox>

              {/* Input for total questions for each portion */}
              <Form.Item
                label={`Total Questions for ${portion}`}
                name={`totalChapterQuestions_${portion}`}
              >
                <Input
                  type="number"
                  placeholder={`Enter total questions for ${portion}`}
                  onChange={(e) =>
                    handlePortionQuestionChange(
                      chapterModalData.chapter,
                      portion,
                      Number(e.target.value)
                    )
                  }
                />
              </Form.Item>

              {/* Select for question format for each portion */}
              <Form.Item
                label={`Question Format for ${portion}`}
                name={`questionFormat_${portion}`}
              >
                <Select
                  placeholder={`Select question format for ${portion}`}
                  onChange={(value) =>
                    handleQuestionFormatChange(
                      chapterModalData.chapter,
                      portion,
                      value
                    )
                  }
                >
                  <Option value="True/False">True/False</Option>
                  <Option value="Short Answer">Short Answer</Option>
                  <Option value="Fill in the Blanks">Fill in the Blanks</Option>
                </Select>
              </Form.Item>
            </div>
          ))}
        </Form>
      </Modal>

      {/* Feedback Modal */}
      <Modal
        title="Why are you not satisfied with the generated exam?"
        visible={isModalVisible}
        onOk={handleSubmitFeedback}
        onCancel={() => setIsModalVisible(false)}
      >
        <Radio.Group onChange={(e) => setFeedback(e.target.value)}>
          {feedbackOptions.map((option) => (
            <Radio key={option} value={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default TeacherQuestionGenerator;
