import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Select,
  Spin,
  Modal,
  Divider,
  Typography,
  message,
} from "antd";

import jsPDF from "jspdf"; // Import jsPDF for PDF generation
import "./GeneratedQuestionHistory.css";
import useFetch from "../../../hooks/useFetch";

const { Title, Text } = Typography;
const { Option } = Select;

const GeneratedQuestionHistory = () => {
  const [filteredExams, setFilteredExams] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("Grade 1");
  const [selectedSubject, setSelectedSubject] = useState("Math");

  const [gradeList, setGradeList] = useState([]); // Initialize as an empty array
  const [subjectList, setSubjectList] = useState([]); // Initialize as an empty array

  const [dataRes, setDataRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentExam, setCurrentExam] = useState(null);
  // Refactored useFetch for teacher Task
  const [tasks, setTasks] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskFetchError, setTaskFetchError] = useState(null);
  const { get: fetchQuestions } = useFetch("/api/questions", {
    setLoading,
    setError: setFetchError,
    setData: setDataRes,
  });
  const { get: fetchTask } = useFetch("/api/teacherTask", {
    setLoading: setTaskLoading,
    setError: setTaskFetchError,
    setData: setTasks,
  });

  useEffect(() => {
    fetchTask();
  }, []);
  useEffect(() => {
    if (tasks?.length) {
      // Check if tasks is defined and has elements
      const uniqueGrades = [...new Set(tasks.map((task) => task.GRADE))];
      const uniqueSubject = [
        ...new Set(tasks.map((task) => task["SUBJECT NAME"])),
      ];
      setGradeList(uniqueGrades);
      setSubjectList(uniqueSubject);
    }
  }, [tasks]);

  useEffect(() => {
    fetchQuestions({
      grade: selectedGrade,
      subject: selectedSubject,
    });
  }, [selectedGrade, selectedSubject]);

  useEffect(() => {
    setFilteredExams(dataRes?.exams || []);
    console.log("Filtered Exams:", dataRes, filteredExams);
  }, [dataRes]);

  const handleGradeChange = (value) => {
    setSelectedGrade(value);
  };

  const handleSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  const parseQuestions = (questions) => {
    try {
      return typeof questions === "string" ? JSON.parse(questions) : questions;
    } catch (error) {
      console.error("Failed to parse questions:", error);
      return [];
    }
  };

  const showDetails = (exam) => {
    setCurrentExam(exam);
    setIsModalVisible(true);
  };

  const handlePrint = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;

    let yOffset = 20; // Start position on the page

    // Exam Header - Listed in two rows
    const headerRow1 = `Grade: ${currentExam.grade}                 | Subject: ${currentExam.subject}`;
    const headerRow2 = `Semester: ${currentExam.semester}           | Assessment Type: ${currentExam.assessmentType}`;
    const headerRow3 = `Allowed Hour: ${
      currentExam.allowedHour
    }                                                               | Date: ${currentExam.updatedAt.slice(
      0,
      10
    )}`;

    // Set header font and content
    doc.setFontSize(14);
    doc.text("Exam Details", 10, yOffset);
    yOffset += 10;

    doc.setFontSize(12);
    doc.text(headerRow1, 10, yOffset);
    yOffset += 10;
    doc.text(headerRow2, 10, yOffset);
    yOffset += 10;
    doc.text(headerRow3, 10, yOffset);
    yOffset += 20; // Add some extra space before the border

    // Draw a border line between header and questions
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.line(10, yOffset, 200, yOffset);
    yOffset += 10; // Add space after the line

    // Add Questions
    parseQuestions(currentExam.questions).forEach((q, idx) => {
      const questionText = `Q${idx + 1}: ${q.question}`;
      const answerText = `Answer: ${q.answer}`;

      // Break text into lines if it exceeds the page width
      const questionLines = doc.splitTextToSize(questionText, 180);
      const answerLines = doc.splitTextToSize(answerText, 180);

      if (yOffset + questionLines.length * 10 > pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }

      doc.setFontSize(12);
      doc.text(questionLines, 15, yOffset);
      yOffset += questionLines.length * 10 + 10;

      if (yOffset + answerLines.length * 10 > pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }

      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(answerLines, 15, yOffset);
      yOffset += answerLines.length * 10 + 1;

      if (yOffset >= pageHeight - 20) {
        doc.addPage();
        yOffset = 20;
      }
    });

    doc.save(`${currentExam.grade}-${currentExam.subject}-exam.pdf`);
  };

  return (
    <div className="exam-history-container">
      <h2>Generated Exam History</h2>

      <div className="filter-section">
        <Select
          placeholder="Filter by Grade"
          style={{ width: 200, marginRight: 20 }}
          onChange={handleGradeChange}
          value={selectedGrade}
        >
          {gradeList.map((grade) => (
            <Option key={grade} value={grade}>
              {grade}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Filter by Subject"
          style={{ width: 200 }}
          onChange={handleSubjectChange}
          value={selectedSubject}
        >
          {subjectList.map((subject) => (
            <Option key={subject} value={subject}>
              {subject}
            </Option>
          ))}
        </Select>
      </div>

      {loading ? (
        <Spin size="large" />
      ) : filteredExams.length === 0 ? (
        <p>No exam history found.</p>
      ) : (
        filteredExams.map((exam, index) => (
          <Card key={index} className="exam-history-card">
            <div className="exam-header">
              <div className="exam-info">
                <p>
                  <strong>Grade:</strong> {exam.grade}
                </p>
                <p>
                  <strong>Assessment Type:</strong> {exam.assessmentType}
                </p>
                <p>
                  <strong>Subject:</strong> {exam.subject}
                </p>
                <p>
                  <strong>Date:</strong> {exam.updatedAt.slice(0, 10)}
                </p>
              </div>
              <Button type="primary" onClick={() => showDetails(exam)}>
                View Details
              </Button>
            </div>
          </Card>
        ))
      )}

      {/* Modal for Exam Details */}
      <Modal
        title={<Title level={3}>Exam Details</Title>}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="print" type="primary" onClick={handlePrint}>
            Print
          </Button>,
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
        ]}
        className="exam-details-modal"
      >
        {currentExam && (
          <>
            {/* Exam Header Information */}
            <div className="exam-header">
              <Title level={4} className="exam-info-title">
                Exam Information
              </Title>
              <Divider />

              <div className="exam-info-section">
                <Text>
                  <strong>Grade:</strong> {currentExam.grade}
                </Text>
                <Text>
                  <strong>Subject:</strong> {currentExam.subject}
                </Text>
                <Text>
                  <strong>Semester:</strong> {currentExam.semester}
                </Text>
                <Text>
                  <strong>Assessment Type:</strong> {currentExam.assessmentType}
                </Text>
                <Text>
                  <strong>Allowed Hour:</strong> {currentExam.allowedHour}
                </Text>
                <Text>
                  <strong>Date:</strong> {currentExam.updatedAt.slice(0, 10)}
                </Text>
              </div>
            </div>

            <Divider />

            {/* Questions Section */}
            <div className="exam-questions-section">
              <Title level={4} className="questions-title">
                Questions
              </Title>
              {parseQuestions(currentExam.questions).map((q, idx) => (
                // console.log("---------99", q, idx)

                <div key={idx} className="question-item">
                  <div className="question-text">
                    <Text strong>{`Question ${idx + 1}:`}</Text>
                    <Text>{q.question}</Text>
                  </div>
                  <div className="answer-text">
                    <Text type="secondary" strong>
                      Answer:
                    </Text>
                    <Text>{q.answer}</Text>
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default GeneratedQuestionHistory;
