import SignUpForm from "../../../components/sign-up/SignUp";

const SignUp = () => {
  return (
    <>
      <SignUpForm />
    </>
  );
};

export default SignUp;
