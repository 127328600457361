import { useState } from "react";
import { postApi } from "../services/api";

const usePost = (endpoint, prop) => {
  const post = async (postValue) => {
    try {
      prop?.setLoading(true);
      const result = await postApi(endpoint, postValue);
      console.log(result);

      prop?.setData(result);
      prop?.setLoading(false);
    } catch (err) {
      prop?.setLoading(false);

      if (err?.response) {
        const errRes = await err.response.data;
        console.log(errRes);

        return prop?.setError(errRes);
      }
      console.error(err);
      if (err.name === "TypeError" || err.name === "SyntaxError")
        err.message = "System Error";
      prop?.setError(err);
    } finally {
      prop?.setLoading(false);
    }
  };

  const reset = () => {
    prop?.setLoading(false);
    // prop?.setData(null);
    prop?.setError(null);
  };

  return { reset, post };
};

export default usePost;
