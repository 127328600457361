import React, { useMemo } from "react";
import { Button } from "antd";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

const StaffIdTable = ({
  title,
  data = [],
  loading,
  searchText,
  selectedColumn,
  handleAdd,
  handleRemove,
  actionType,
}) => {
  const filteredData = useMemo(() => {
    return data.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [data, searchText, selectedColumn]);

  const generateColumns = () => {
    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div
              className="tableSubCols heading"
              style={{ fontWeight: "bolder" }}
            >
              {record["FULL NAME"].toUpperCase()}
            </div>
            <div className="tableSubCols details">ID: {record["ID"]}</div>
            <div className="tableSubCols details">Sex: {record["SEX"]}</div>
            <div className="tableSubCols details">
              Edu Level: {record["EDUC LEVEL"]}
            </div>
          </>
        ),
      },
      {
        title: "PHONE",
        key: "phone",
        render: (text, record) => (
          <>
            <div
              className="tableSubCols details"
              style={{ fontWeight: "bolder" }}
            >
              Phone: {record["PHONE"]}
            </div>
            <div className="tableSubCols details">Email: {record["EMAIL"]}</div>
            <div className="tableSubCols details">Role: {record["ROLE"]}</div>
          </>
        ),
      },
      {
        title: "REGION",
        key: "region",
        render: (text, record) => (
          <>
            <div
              className="tableSubCols heading"
              style={{ fontWeight: "bolder" }}
            >
              {record["REGION"]}
            </div>
            <div className="tableSubCols heading">
              Woreda: {record["WOREDA"]}
            </div>
            <div className="tableSubCols details">
              Sub City: {record["SUB CITY"]}
            </div>
          </>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div
              className="tableSubCols heading"
              style={{ fontWeight: "bolder" }}
            >
              {convertToDisplayDate(record["CREATED"])}
            </div>
            <div className="tableSubCols details">
              Updated: {convertToDisplayDate(record["UPDATED"])}
            </div>
            <div className="tableSubCols details">
              Created by: {record["CREATED BY"]}
            </div>
          </>
        ),
      },
    ];

    const actionColumn = {
      title: "Action",
      key: "action",
      render: (text, record) =>
        actionType === "add" ? (
          <Button type="primary" onClick={() => handleAdd(record)}>
            Add
          </Button>
        ) : (
          <Button
            onClick={() => handleRemove(record["ID"])}
            style={{ borderColor: "red", color: "red" }}
          >
            Remove
          </Button>
        ),
    };

    return [...commonColumns, actionColumn];
  };

  const columns = useMemo(() => generateColumns(), [actionType]);

  return (
    <DynamicTable
      title={title}
      data={filteredData}
      loading={loading}
      customColumns={columns}
      scroll={{ x: 1000 }}
    />
  );
};

export default StaffIdTable;
