// AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";

import ErrorRoutes from "./ErrorRoutes";
import PricingPage from "../pages/web/pricing/PricingPage";
import SchoolRegistration from "../components/registration-form-container/school-registration/SchoolRegistration";

import Auth from "../pages/auth/Auth";
import SignUp from "../pages/web/sign-up/SignUp";
import SubscriptionPaymentRoutes from "./routes/SubscriptionPaymentRoute";
import SchoolCode from "../components/school-auth/SchoolAuth";
import Login from "../components/login/Login";

const LandingPage = lazy(() => import("../pages/web/landing-page/LandingPage"));

const WebRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route
          path="/subscription/payment/*"
          element={<SubscriptionPaymentRoutes />}
        />
        <Route path="/school-registration" element={<SchoolRegistration />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/school" element={<SchoolCode />} />
        <Route path="/auth/sign-up" element={<SignUp />} />
        <Route path="*" element={<ErrorRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default WebRoutes;
