import { useState } from "react";
import useFetch from "./useFetch";

const useAuth = () => {
  const [authRes, setAuthRes] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  const { get: getAuth } = useFetch("auth/authenticate", {
    setLoading: setAuthLoading,
    setError: setAuthError,
    setData: setAuthRes,
  });

  // Return state and fetch function
  return {
    authRes,
    authLoading,
    authError,
    getAuth,
  };
};

export default useAuth;
