import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../utils/helper";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <>
          <Button
            type="primary"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to Home
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleLogout();
              navigate("/auth/login");
            }}
          >
            Logout
          </Button>
        </>
      }
    />
  );
};

export default NotFound;
