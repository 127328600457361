import React, { useState, useEffect } from "react";
import { Card, Button, Select, Table, Modal, message, Spin } from "antd";
import "./Feedback.css";
import useFetch from "../../../../hooks/useFetch";
import { gradeList } from "../../../../services/data";

const { Option } = Select;

const AdminFeedbackAnalysis = () => {
  // Initial filters set to default values
  const [filters, setFilters] = useState({ grade: "Grade 1", subject: "Math" });

  const [dataRes, setDataRes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  // Fetch feedback data from backend
  const { get: fetchFeedback } = useFetch("/api/questions/feedback", {
    setLoading,
    setError: setFetchError,
    setData: setDataRes,
  });

  // Fetch feedback data when filters or component mounts
  useEffect(() => {
    fetchFeedback(filters);
  }, [filters]);

  // Update the filtered data when the response changes
  useEffect(() => {
    if (Array.isArray(dataRes.exams)) {
      setFilteredData(dataRes.exams);
      console.log(".........", dataRes.exams);
    }
  }, [dataRes]);

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const viewExamDetails = (exam) => {
    setSelectedExam(exam);
  };

  const exportFeedback = () => {
    message.success("Feedback exported successfully!");
    console.log("Exporting feedback:", filteredData);
  };

  const columns = [
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Subject", dataIndex: "subject", key: "subject" },
    { title: "Question Type", dataIndex: "examType", key: "examType" },
    { title: "Teacher", dataIndex: "teacherName", key: "teacherName" },
    { title: "Feedback", dataIndex: "feedback", key: "feedback" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button type="primary" onClick={() => viewExamDetails(record)}>
          View Exam
        </Button>
      ),
    },
  ];

  return (
    <div className="feedback-analysis-container">
      <h2>Feedback Analysis</h2>

      <div className="filter-section">
        <Select
          placeholder="Filter by Grade"
          style={{ width: 180, marginRight: 10 }}
          onChange={(value) => handleFilterChange("grade", value)}
          value={filters.grade}
        >
          {gradeList.map((grade) => (
            <Option key={grade} value={grade}>
              {grade}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Filter by Subject"
          style={{ width: 180, marginRight: 10 }}
          onChange={(value) => handleFilterChange("subject", value)}
          value={filters.subject}
        >
          <Option value="Math">Math</Option>
          <Option value="Science">Science</Option>
          <Option value="English">English</Option>
        </Select>
      </div>

      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          className="feedback-table"
        />
      )}

      {selectedExam && (
        <Modal
          visible={!!selectedExam}
          title={`Exam Details - ${selectedExam.subject}`}
          onCancel={() => setSelectedExam(null)}
          footer={null}
        >
          <p>
            <strong>Grade:</strong> {selectedExam.grade}
          </p>
          <p>
            <strong>Teacher:</strong> {selectedExam.createdBy}
          </p>
          <p>
            <strong>School:</strong> {selectedExam.schoolName}
          </p>
          <p>
            <strong>Date:</strong> {selectedExam.date}
          </p>
          <div className="questions-container">
            <h4>Questions and Answers</h4>
            {selectedExam.questions.map((qa, idx) => (
              <div key={idx} className="question-answer">
                <p>
                  <strong>Q{idx + 1}:</strong> {qa.question}
                </p>
                <p>
                  <strong>A{idx + 1}:</strong> {qa.answer}
                </p>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdminFeedbackAnalysis;
