import { Button, Popconfirm } from "antd";

export function generateRandomHexColors(numColors) {
  const colorList = [];
  for (let i = 0; i < numColors; i++) {
    let hexCode = "#";
    // Generate 6 random hexadecimal digits
    for (let j = 0; j < 6; j++) {
      hexCode += Math.floor(Math.random() * 16).toString(16);
    }
    colorList.push(hexCode);
  }
  return colorList;
}

export const colorData = ["#059bff", "#ff4069", "#059bff", "#ff4069"];

export const handleFilterNumber = (e) => {
  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
  }
};

export const generateColumns = (data, tableActions) => {
  if (data?.length === 0) return [{}];
  const keys = Object.keys(data ? data[0] : [{}]);
  const columns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    key: key,
  }));

  // Add Actions column
  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {tableActions?.map((tableAction, index) => {
          return tableAction.confirm ? (
            <Popconfirm
              key={index}
              title={tableAction.confirmMessage}
              onConfirm={() => tableAction.onClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger={tableAction.danger}>
                {tableAction.label}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              key={index}
              type="link"
              danger={tableAction.danger}
              onClick={() => tableAction.onClick(record)}
            >
              {tableAction.label}
            </Button>
          );
        })}
      </span>
    ),
  });

  return columns;
};

export const convertToDisplayDate = (date) => {
  const dateObj = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(dateObj);
};

export const checkAndNavigate = (location, navigate, message) => {
  const lastUrl = localStorage.getItem("lastUrl");
  const userToken = localStorage.getItem("userAccessToken");
  const schoolToken = localStorage.getItem("schoolAccessToken");
  if (lastUrl.includes("auth/sign-up") && message === "school-token-required") {
    return navigate("/school-registration");
  }

  if (!schoolToken) {
    return navigate("/auth/school");
  }
  if (!userToken && lastUrl?.includes("/app")) {
    return navigate("/auth/login");
  }
  if (message === "user-token-required") {
    return navigate("/auth/login");
  }
  if (message === "school-token-required") {
    return navigate("/auth/school");
  }
  if (location) return navigate(location);
  navigate(lastUrl || "/");
};

export const handleLogout = () => {
  localStorage.setItem("userAccessToken", "");
  window.location.replace("/");
};

export const getClassSections = (selectedGrade, gradeDivisions = []) => {
  if (!gradeDivisions.length) return [];
  const grade = gradeDivisions.find((ele) => {
    return ele.name === selectedGrade;
  });

  if (grade?.sectionIdentifier === "alphabet")
    return ["A", "B", "C", "D", "F", "G", "H", "I", "J", "K"];
  if (grade?.sectionIdentifier === "numeric")
    return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  return [];
};
