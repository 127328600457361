import React, { useEffect, useState } from "react";
import { FaBell, FaQuestion, FaUser } from "react-icons/fa";
import { Button, Layout, Menu } from "antd";
import "./Header.css";
import { handleLogout } from "../../utils/helper.js";
import { useNavigate } from "react-router-dom";
import { useCollapsed } from "../../context/CollapsedContext.js";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Header } = Layout;

function HeaderComp() {
  const { collapsed, setCollapsed } = useCollapsed();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  const navigate = useNavigate();
  const schoolName = localStorage.getItem("schoolName") || "School Name";
  const schoolLogo = localStorage.getItem("schoolLogo");

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1000;
      setIsSmallScreen(smallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    {
      key: "1",
      label: (
        <span>
          <FaBell style={{ marginRight: "4px" }} />
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span>
          <FaQuestion style={{ marginRight: "4px" }} />
        </span>
      ),
    },
    {
      key: "subMenu",
      label: (
        <span>
          <FaUser style={{ marginRight: "4px" }} />
        </span>
      ),
      children: [
        {
          key: "3",
          label: (
            <Button
              type="text"
              onClick={handleLogout}
              style={{ width: "100%" }}
            >
              Logout
            </Button>
          ),
        },
      ],
    },
  ];

  return (
    <Header
      id="Header"
      className="site-layout-background"
      style={{
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
      }}
    >
      <div
        className="header-logo"
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        {schoolLogo && (
          <img
            className="logo-img"
            src={schoolLogo.replace("./", "/")}
            alt="school logo"
            style={{ height: "40px", marginRight: "8px" }}
          />
        )}
        <span
          className="schoolName"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          {schoolName}
        </span>
      </div>
      <div
        style={{
          padding: "0 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}
      >
        <Menu
          mode="horizontal"
          items={menuItems}
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "none",
          }}
        />
        {isSmallScreen && (
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        )}
      </div>
    </Header>
  );
}

export default HeaderComp;
