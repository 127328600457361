import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function AccNav({ children }) {
  const navigate = useNavigate();
  const handleLoginNavigate = () => {
    navigate("/auth/school");
  };
  return (
    <div id="LandingPage">
      <div className="main-nav" id="main-nav">
        <div className="container main-nav-container">
          <div
            className="logo-container"
            onClick={() => {
              console.log("clicked");
              navigate("/");
            }}
          >
            <img src="../../images/logo.svg" alt="Logo" />
            <a className="logo">
              <span className="text-secondary">TOFI</span>
            </a>
          </div>

          <div className="menu-btn-container">
            <a href="#" className="btn-menu">
              <i className="fa-solid fa-bars fa-2x"></i>
            </a>
          </div>

          <div className="main-menu">
            <ul>
              <li>
                <a href="#" className="manu-link">
                  Features
                </a>
              </li>
              <li className="pricing">
                <Link to="/pricing" className="manu-link">
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          <div className="auth-container">
            <ul className="auth-ul">
              <li>
                <button className="btn" onClick={handleLoginNavigate}>
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
