import React, { useState, useMemo } from "react";
import { Row } from "antd";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

export const excludedStaffCols = [
  "ID",
  "FULL NAME",
  "EMAIL",
  "EDUC LEVEL",
  "ROLE",
  "SEX",
  "REGION",
  "SUB CITY",
  "PHONE",
  "CREATED",
  "UPDATED",
  "CREATED BY",
];

const generateColumns = (data) => {
  if (!data || data.length === 0) return [];

  const commonColumns = [
    {
      title: "INFO",
      key: "info",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {record["FULL NAME"].toUpperCase()}
          </div>

          <div className="tableSubCols details">ID: {record["ID"]}</div>
          <div className="tableSubCols details">Sex: {record["SEX"]}</div>
          <div className="tableSubCols details">
            Edu Level: {record["EDUC LEVEL"]}
          </div>
        </> //0911429007
      ),
    },
    {
      title: "PHONE",
      key: "phone",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols details"
            style={{ fontWeight: "bolder" }}
          >
            Phone: {record["PHONE"]}
          </div>
          <div className="tableSubCols details">Email: {record["EMAIL"]}</div>
          <div className="tableSubCols details">Role: {record["ROLE"]}</div>
        </>
      ),
    },

    {
      title: "REGION",
      key: "region",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {record["REGION"]}
          </div>
          <div className="tableSubCols details">
            Sub City: {record["SUB CITY"]}
          </div>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "created",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {convertToDisplayDate(record["CREATED"])}
          </div>
          <div className="tableSubCols details">
            Updated: {convertToDisplayDate(record["UPDATED"])}
          </div>
          <div className="tableSubCols details">
            Created by: {record["CREATED BY"]}
          </div>
        </>
      ),
    },
  ];

  const sampleItem = data[0];
  const keys = Object.keys(sampleItem).filter(
    (key) => !excludedStaffCols.includes(key)
  );

  const dynamicColumns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    key,
    dataIndex: key,
  }));

  //   dynamicColumns.push({
  //     title: "Actions",
  //     key: "actions",
  //     render: (text, record) => (
  //       <span>
  //         <Button type="link" onClick={() => handleEdit(record)}>
  //           <EditOutlined />
  //         </Button>
  //       </span>
  //     ),
  //   });

  return [...commonColumns, ...dynamicColumns];
};

// const handleEdit = (value) => {
//   setEditingStaff(value);
//   setIsEditModalVisible(true);
// };
const StaffTable = ({ filteredData = [], staffLoading }) => {
  const customColumns = useMemo(
    () => generateColumns(filteredData),
    [filteredData]
  );

  return (
    <div id="StaffTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}></Row>
      <DynamicTable
        data={filteredData}
        loading={staffLoading}
        customColumns={customColumns}
        scroll={{ x: 800 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default StaffTable;
