import React from "react";
import LayoutComponent from "../../../components/layout/Layout";
import RegistrarRoutes from "../../../router/routes/RegistrarRoute";

const Registrar = () => {
  return (
    <LayoutComponent>
      <RegistrarRoutes />
    </LayoutComponent>
  );
};

export default Registrar;
