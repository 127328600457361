import { useRoutes } from "react-router-dom";
import AdminFeedbackAnalysis from "../../components/question-generator-comp/admin-question-generator/feedback/Feedback";
import QuestionHistory from "../../components/question-generator-comp/admin-question-generator/question-history/QuestionHistory";
import AITraining from "../../components/question-generator-comp/admin-question-generator/train-ai/AITraining";

const AdminQuestionRoute = () => {
  const routes = useRoutes([
    {
      path: "/question-history",
      element: <QuestionHistory />,
    },
    {
      path: "/ai-training",
      element: <AITraining />,
    },
    {
      path: "/admin-analysis",
      element: <AdminFeedbackAnalysis />,
    },

    // {
    //   path: "/*",
    //   element: <AdminQuestionGenerator />,
    // },
  ]);

  return routes;
};

export default AdminQuestionRoute;
