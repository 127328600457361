import React, { useState, useMemo, useEffect } from "react";
import { Table, Button, Select, Input, Row, Col, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";

import StudentIdTable from "../../tables/student-Id-table/StudentIdTable.jsx";

import useFetch from "../../../hooks/useFetch";
import { grade, section } from "../../../services/data";
import { convertToDisplayDate } from "../../../utils/helper";
import { printStudId } from "../../../utils/printId.js";

const StudentId = () => {
  const [showPrintId, setShowPrintId] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [selectedGrade, setSelectedGrade] = useState("Nursery");
  const [selectedSection, setSelectedSection] = useState("A");

  const [studentsNeedingId, setStudentsNeedingId] = useState([]);
  const [fetchStudLoading, setFetchStudLoading] = useState(false);
  const [fetchStudError, setFetchStudError] = useState(null);
  const [studData, setStudData] = useState([]);

  // state for id fetch

  const [fetchIdLoading, setFetchIdLoading] = useState(false);
  const [fetchIdError, setFetchIdError] = useState(null);
  const [idData, setIdData] = useState([]);

  // Fetching the students from the backend
  const { get: fetchStudent } = useFetch("/api/student", {
    setLoading: setFetchStudLoading,
    setError: setFetchStudError,
    setData: setStudData,
  });

  // Fetch Student Ids from backend

  const { get: fetchId } = useFetch("/api/studentId", {
    setLoading: setFetchIdLoading,
    setError: setFetchIdError,
    setData: setIdData,
  });

  useEffect(() => {
    fetchStudent({ grade: selectedGrade, section: selectedSection }); // Fetch students whenever the selected grade or section changes
  }, [selectedGrade, selectedSection]);

  useEffect(() => {
    fetchId();
  }, []);

  // const handleSearchChange = (e) => setSearchText(e.target.value);
  // const handleColumnChange = (value) => setSelectedColumn(value);

  const handleSearchChange = (e) => setSearchText(e.target.value);

  const filteredData = useMemo(() => {
    return studData.filter((student) =>
      student["FULL NAME"]?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [studData, searchText]);

  const handleAddStudentForId = (student) => {
    if (!studentsNeedingId.some((s) => s["STUD ID"] === student["STUD ID"])) {
      setStudentsNeedingId((prev) => [...prev, student]);
    } else {
      message.info("Student is already in the list for ID generation.");
    }
  };

  const handleRemoveStudent = (studentId) => {
    setStudentsNeedingId((prev) =>
      prev.filter((s) => s["STUD ID"] !== studentId)
    );
  };

  const handlePrintId = () => {
    if (studentsNeedingId.length > 0) {
      printStudId(studentsNeedingId, idData.imgUri);
    } else {
      message.warning("No students available to print IDs.");
    }
  };

  const gradeOptions = grade.map((col) => ({ key: col.val, value: col.val }));
  const sectionOptions = section.map((sec) => ({ key: sec, value: sec }));

  return (
    <div id="StudentId">
      <div className="header">
        <h2>Students</h2>
        <Breadcrumb />
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Grade"
              value={selectedGrade}
              onChange={setSelectedGrade}
              style={{ width: 200 }}
              options={gradeOptions}
            />
          </Col>
          <Col>
            <Select
              placeholder="Section"
              value={selectedSection}
              onChange={setSelectedSection}
              style={{ width: 200 }}
              options={sectionOptions}
            />
          </Col>
        </Row>

        <StudentIdTable
          studentData={filteredData}
          studentLoading={fetchStudLoading}
          handleAddStudentForId={handleAddStudentForId}
        />

        <h2 style={{ marginTop: "20px" }}>Students Who Need ID</h2>
        <StudentIdTable
          studentData={studentsNeedingId}
          studentLoading={fetchStudLoading}
          onAction={handleRemoveStudent}
          actionType="Remove"
        />

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handlePrintId}
            disabled={studentsNeedingId.length === 0}
          >
            Print ID
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StudentId;
