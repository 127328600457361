import React, { useState, useEffect } from "react";
import { Form, Select, Button, List } from "antd";

const { Option } = Select;

const studentsData = {
  "1-B": [
    { id: "St-16-O-A-H#Bc268", name: "Abdellah Abdulatif Jemal" },
    { id: "St-21-1-B-H#Bc270", name: "Sara Ahmed" },
  ],
  "2-B": [
    { id: "St-16-O-A-H#Bc264", name: "Ahafiz Ali Yusuf" },
    // Add more students as needed
  ],
};

const AttendanceForm = ({ form, selectedGrade, selectedSection, reset }) => {
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  // Update student options based on selected grade and section
  useEffect(() => {
    const key = `${selectedGrade}-${selectedSection}`;
    if (studentsData[key]) {
      setStudentOptions(studentsData[key]);
    } else {
      setStudentOptions([]);
    }
  }, [selectedGrade, selectedSection]);

  useEffect(() => {
    if (!reset) {
      setSelectedStudents([]); // Clear selected students when modal is closed
    }
  }, [reset]);

  const handleStudentSelect = (value) => {
    const selectedStudent = studentOptions.find(
      (student) => student.id === value
    );
    if (selectedStudent && !selectedStudents.includes(selectedStudent)) {
      setSelectedStudents([...selectedStudents, selectedStudent]);
    }
  };

  const handleMarkAbsent = () => {
    form.setFieldsValue({
      selectedStudents: selectedStudents.map((student) => student.id),
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item label="Search Student" name="selectedStudents">
        <Select
          showSearch
          placeholder="Select a student"
          optionFilterProp="children"
          onSelect={handleStudentSelect}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {studentOptions.map((student) => (
            <Option key={student.id} value={student.id}>
              {student.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <List
        header={<div>Selected Students</div>}
        bordered
        dataSource={selectedStudents}
        renderItem={(item) => <List.Item>{item.name}</List.Item>}
      />

      <Button type="primary" onClick={handleMarkAbsent}>
        Mark Absent
      </Button>
    </Form>
  );
};

export default AttendanceForm;
