import { useRoutes } from "react-router-dom";
import SubscriptionPayment from "../../pages/subscription-payment/SubscriptionPayment";
import ConfigurationPendingPage from "../../pages/web/payment/ConfigurationPendingPage";

const SubscriptionPaymentRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <SubscriptionPayment /> },
    { path: "/pending", element: <ConfigurationPendingPage /> },
  ]);

  return routes;
};

export default SubscriptionPaymentRoutes;
