import React, { useState, useMemo } from "react";
import { Button, Input, Row, Col, Select } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

export const excludedStudentCols = [
  "ROLL NUM",
  "STUD ID",
  "FULL NAME",
  "SEX",
  "GRADE",
  "REGION",
  "SUB CITY",
  "WOREDA",
  "MOTHER NAME",
  "AGE",
  "SECTION",
  "PHONE",
  "PHONE HOME",
  "CREATED",
  "UPDATED",
  "CREATED BY",
];

const generateColumns = (data) => {
  if (!data || data.length === 0) return [];

  const commonColumns = [
    {
      title: "INFO",
      key: "info",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            Name: {record["FULL NAME"].toUpperCase()}
          </div>
          <div className="tableSubCols details">
            Role Num: {record["ROLL NUM"]}
          </div>
          <div className="tableSubCols details">ID: {record["STUD ID"]}</div>
          <div className="tableSubCols details">Sex: {record["SEX"]}</div>
        </>
      ),
    },
    {
      title: "PHONE",
      key: "phone",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
          <div className="tableSubCols details">
            Home: {record["PHONE HOME"]}
          </div>
        </>
      ),
    },
    {
      title: "SECTION",
      key: "section",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {record["GRADE"]}
          </div>
          <div className="tableSubCols details">
            {" "}
            Section: {record["SECTION"]}
          </div>
        </>
      ),
    },
    {
      title: "REGION",
      key: "region",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            Region: {record["REGION"]}
          </div>
          <div className="tableSubCols details">
            Sub City: {record["SUB CITY"]}
          </div>
          <div className="tableSubCols details">Woreda: {record["WOREDA"]}</div>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "created",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {convertToDisplayDate(record["CREATED"])}
          </div>
          <div className="tableSubCols details">
            Updated: {convertToDisplayDate(record["UPDATED"])}
          </div>
          <div className="tableSubCols details">
            Created by: {record["CREATED BY"]}
          </div>
        </>
      ),
    },
  ];

  const sampleItem = data[0];
  const keys = Object.keys(sampleItem).filter(
    (key) => !excludedStudentCols.includes(key)
  );

  const dynamicColumns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    key,
    dataIndex: key,
  }));

  dynamicColumns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Button type="link" onClick={() => {}}>
          <EditOutlined />
        </Button>
      </span>
    ),
  });

  return [...commonColumns, ...dynamicColumns];
};

const StudentTable = ({ studentData = [], studentLoading }) => {
  const customColumns = useMemo(
    () => generateColumns(studentData),
    [studentData]
  );

  return (
    <div id="StudentTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}></Row>
      <DynamicTable
        data={studentData}
        loading={studentLoading}
        customColumns={customColumns}
        scroll={{ x: 900 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default StudentTable;
