import React from "react";
import { Layout } from "antd";
import AdminSiderComp from "../sider/AdminSider";
import RegistrarSiderComp from "../sider/RegistrarSider";
import TeacherSiderComp from "../sider/TeacherSider";
import Header from "../header/Header";

const { Content } = Layout;

const LayoutComponent = ({ children }) => {
  const userRole = localStorage.getItem("userRole");

  let Sider;
  if (userRole) {
    switch (userRole) {
      case "admin":
        Sider = <AdminSiderComp />;
        break;
      case "registrar":
        Sider = <RegistrarSiderComp />;
        break;
      case "teacher":
        Sider = <TeacherSiderComp />;
        break;
      default:
        Sider = null;
    }
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {Sider}
      <Layout>
        <Layout className="layout-content">
          <Header />
          <Content style={{ margin: "24px 16px 0" }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
